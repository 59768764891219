import { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import Permission from "../utils/functions/Permission";
import { motion } from "framer-motion/dist/framer-motion"


const RequireAuth = ({ loggedUser }) => {
  const location = useLocation();
  const path = location.pathname
  const [permission, setPermission] = useState()

  useEffect(() => {
    if (loggedUser?.user?.userType === "employee") {
      Permission(loggedUser)
        .then(result => setPermission(result.Permissions.map(data => ({ id: data.id, name: data.name }))));
    }
  }, [loggedUser])

  if (loggedUser?.user?.userType === "employee") {
    if (permission) {
      const isIndexPage = (matchingPermission) => {
        return (
          matchingPermission?.name === `${path.slice(1)}s-index` ||
          matchingPermission?.name === `${path.slice(1)}-index`
        );
      };

      const isAddEditPage = (matchingPermission) => {
        const permissions = permission.filter((role) =>
          role.name.split("-")[0].includes(path.split('/')[1])
        )
        const pathWithoutSlash = path.slice(1);
        const pathComponents = pathWithoutSlash.split('/');
        let lastPathSegment
        let ID
        if (pathComponents.length === 3) {
          lastPathSegment = pathComponents[pathComponents.length - 2];
          ID = pathComponents[pathComponents.length - 1];
          if (permissions.find(role => role.name.includes("edit")) && lastPathSegment.includes("AddEdit") && ID)
            return true
        } else {
          lastPathSegment = pathComponents[pathComponents.length - 1];
          if (permissions.find(role => role.name.includes("create")) && lastPathSegment.includes("AddEdit"))
            return true
        }

      };

      const matchingPermission = permission.find((role) =>
        role.name.split("-")[0].includes(path.slice(1))
      );

      if (isIndexPage(matchingPermission)) {
        return <Outlet />;
      } else if (
        path.slice(1) === "checkin-and-checkout" ||
        path.includes("my-leaves") ||
        path.includes("apply") ||
        path.includes("my-report") ||
        (path.includes("AddEdit") && isAddEditPage())
      ) {
        return <Outlet />;
      } else {
        return (
          <Navigate
            to="/unauthorized"
            state={{ from: location }}
            replace
          />
        );
      }
    }
  } else if (
    loggedUser?.user?.userType === "super_admin" ||
    loggedUser?.user?.userType === "organization"
  ) {
    // return <motion.div
    //   animate={{ x: 100 }}
    //   transition={{ ease: "easeOut", duration: 2 }}
    // >
    //   <Outlet />
    // </motion.div>
    return <Outlet />
  }
};

export default RequireAuth;
