import { useEffect, useState } from 'react'
import { RxCross1 } from 'react-icons/rx'

const Filter = (props) => {
    const [cross, EnableCross] = useState(false)
    const handleQueryChange = (e) => {
        props.setQuery(e.target.value)
    }

    useEffect(() => {
        if (props.query !== "")
            EnableCross(true)
    }, [props.query])

    return (
        <label className='filterLabel'>
            <input
                value={props.query}
                placeholder="Filter"
                onChange={handleQueryChange}
                className="form-control filter"
                autoComplete='off'
            />
            {cross && <RxCross1
                onClick={() => {
                    EnableCross(false)
                    props.setQuery("")
                }}
                className="filterCross" />}
        </label>
    )
}
export default Filter