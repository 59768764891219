import React, { useState, useEffect } from 'react'
import defaultPicture from '../../assets/images/defaultProfile.png'
import classes from '../../assets/styles/organization.module.css'
import { WithContext as ReactTags } from 'react-tag-input';
import { BiImageAdd } from 'react-icons/bi'
import { FaTrashAlt } from 'react-icons/fa'
import { useNavigate } from "react-router-dom";
import { get, put } from "../../server";
import ValidateSchema from "../../helpers/ValidateSchema";
import AddEditOrganizationSchema from '../../utils/SchemasValidation/AddEditOrganizationSchema'
import { ORGANIZATION } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'

const KeyCodes = {
    enter: 13,
    tab: 9
};

const editOrg = {
    organization_name: "",
    organization_email: [],
    organization_number: [],
    organization_address: "",
    organization_city: "",
    organization_logo: "Default"
};

const App = ({ organizationID }) => {
    const delimiters = [KeyCodes.enter, KeyCodes.tab];

    const [organization_email, setOrganizationEmail] = useState([])
    const [organization_number, setOrganizationNumber] = useState([])

    // const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
    const [organization, setOrganization] = useState(editOrg)

    const [Orgerrors, setOrgErrors] = useState({});
    // const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [tagIndex, setTagIndex] = useState()

    const handleOrgChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setOrganization({ ...organization, [name]: value });
    };

    // handle Validation
    const validateForm = () => {
        let orgErrors = ValidateSchema(organization, AddEditOrganizationSchema)
        setOrgErrors(orgErrors);
        if (Object.keys(orgErrors).length) return false;
        return true;
    };

    const [srcO, selectFileO] = useState(null)
    const [fileNameO, setFileNameO] = useState(null)
    const [imageO, setImageO] = useState(null)

    const handleFileChangeO = e => {
        setFileNameO(e.target.files[0].name);
        selectFileO(URL.createObjectURL(e.target.files[0]))
        setImageO(e.target.files[0])
    }

    const handleAddition = tag => {
        setOrganizationNumber([...organization_number, tag])
        setOrganization({
            ...organization,
            organization_number: [...organization.organization_number, tag.text]
        })
    };

    const handleDelete = (i) => {
        setOrganizationNumber(organization_number.filter((tag, index) => index !== i));
        const numbers = organization_number.map(obj => obj.text).filter((tag, index) => index !== i)
        setOrganization({
            ...organization,
            organization_number: numbers
        })
    };

    const handleEdit = (inputValue,) => {
        const tags = [...organization_number];
        if (tagIndex !== -1) {
            tags[tagIndex] = { id: inputValue, text: inputValue };
            if (delimiters)
                setOrganizationNumber(tags);
        }
    };

    const handleAdditionEmail = tag => {
        setOrganizationEmail([...organization_email, tag])
        setOrganization({
            ...organization,
            organization_email: [...organization.organization_email, tag.text]
        })
    };

    const handleDeleteEmail = (i) => {
        setOrganizationEmail(organization_email.filter((tag, index) => index !== i));
        const emails = organization_email.map(obj => obj.text).filter((tag, index) => index !== i)
        setOrganization({
            ...organization,
            organization_email: emails
        })
    };

    const handleEditEmail = (inputValue,) => {
        const tags = [...organization_email];
        if (tagIndex !== -1) {
            tags[tagIndex] = { id: inputValue, text: inputValue };
            setOrganizationEmail(tags);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        // setLoading(true);
        let { status, message } = await put(
            ORGANIZATION,
            organization,
            // Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status) {
            navigate("/organization");
        }
        showToast(status, message);
        // setLoading(false);
    };

    const loadOrganization = async () => {
        // setLoading(true);
        let { status, message, data } = await get(
            ORGANIZATION + `/${organizationID}`,
            // Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === "ok") {
            const {
                organization_address,
                organization_city,
                organization_logo,
                organization_name,
                organization_email,
                organization_number
            } = data;

            const emailData = organization_email.split(",")
            const OrgEmails = emailData.map((tag) => ({ id: tag, text: tag }))

            const numbData = organization_number.split(",")
            const OrgNumbs = numbData.map((tag) => ({ id: tag, text: tag }))

            setOrganizationEmail(OrgEmails)
            setOrganizationNumber(OrgNumbs)

            setOrganization({
                organization_address,
                organization_city,
                organization_email,
                organization_logo,
                organization_name,
                organization_number,
            });
        } else {
            showToast(status, message);
        }
        // setLoading(false);
    };

    useEffect(() => {
        if (organizationID) loadOrganization();
    }, [])

    return < >
        <div className={classes.OrganizationSettingsView}>

            <div>
                <label className={classes.relativeItem}>
                    <img src={srcO ?
                        srcO : organization.organization_logo === 'Default' ?
                            defaultPicture : organization.organization_logo
                    }
                        alt=""
                        className={classes.avatar}
                    />

                    <label className={classes.icons}>
                        <label className={classes.ImageDiv2}>
                            <input type='file'
                                className={classes.noDisplay}
                                onChange={handleFileChangeO}
                                accept='image/*'
                            />
                            <BiImageAdd
                                className={classes.imageIcon}
                            />
                            {(organization.organization_logo !== "Default" || srcO) ?
                                <FaTrashAlt onClick={(e) => {
                                    e.preventDefault()
                                    selectFileO(null)
                                    setFileNameO(null)
                                    setImageO(null)
                                }} className={classes.deleteIcon}
                                /> : null}

                            {/* <h3 className='Header'>{ organization.organization_name}</h3> */}
                        </label>
                    </label>
                </label>
            </div>

            <br />
            <div className={classes.startergridO}>
                <span className={classes.organizationStarter}>Name</span>
                <span>
                    <input
                        value={organization.organization_name}
                        className={Orgerrors?.organization_name ?
                            "textInput errorInput "
                            : "textInput"}
                        onChange={handleOrgChange}
                        type="text"
                        placeholder="Twitter"
                        name="organization_name"
                    />
                    <p className="errorText">{Orgerrors?.organization_name || ""}</p>
                </span>
            </div>
            <br />
            <div className={classes.startergridO} >
                <span className={classes.organizationStarter}>Number</span>
                <span>
                    <ReactTags
                        tags={organization_number}
                        delimiters={delimiters}
                        handleDelete={handleDelete}
                        handleAddition={handleAddition}
                        handleTagClick={setTagIndex}
                        handleInputChange={handleEdit}
                        inputFieldPosition="inline"
                        editable
                        allowDragDrop={false}
                        placeholder="Enter Numbers"
                        name="organization_number"
                        classNames={
                            {
                                tags: {
                                    "rti--container": true,
                                    "rti--container errorInput": Orgerrors?.organization_number
                                },
                                tagInput: "rti--input",
                                tagInputField: classes.inputField,
                                tag: "rti--tag",
                                editTagInputField: classes.inputField
                            }
                        }
                        inputFiled
                    />
                    <p className="errorText">{Orgerrors?.organization_number || ""}</p>
                </span>
            </div>

            <div className={classes.startergridO}  >
                <span className={classes.organizationStarter}>Email</span>
                <span>
                    <ReactTags
                        tags={organization_email}
                        delimiters={delimiters}
                        handleDelete={handleDeleteEmail}
                        handleAddition={handleAdditionEmail}
                        handleTagClick={setTagIndex}
                        handleInputChange={handleEditEmail}
                        inputFieldPosition="inline"
                        editable
                        allowDragDrop={false}
                        placeholder="Enter Emails"
                        name="organization_email"
                        classNames={
                            {
                                tags: {
                                    "rti--container": true,
                                    "rti--container errorInput": Orgerrors?.organization_email
                                },
                                // tags: "rti--container",
                                tagInput: "rti--input",
                                tagInputField: classes.inputField,
                                tag: "rti--tag",
                                editTagInputField: classes.inputField
                            }
                        }
                    />
                    <p className="errorText">{Orgerrors?.organization_email || ""}</p>
                </span>
            </div>

            <div className={classes.startergridO}>
                <span className={classes.organizationStarter}>City</span>
                <span>
                    <input
                        value={organization.organization_city}
                        className={Orgerrors?.organization_city ?
                            "textInput errorInput " :
                            "textInput"}
                        onChange={handleOrgChange}
                        type="text"
                        placeholder="City"
                        name="organization_city"
                    />
                    <p className="errorText">{Orgerrors?.organization_city || ""}</p>
                </span>
            </div>

            <div className={classes.startergridO}>
                <span className={classes.organizationStarter}>Address</span>
                <span>
                    <textarea
                        value={organization.organization_address}
                        className={Orgerrors?.organization_address ?
                            `${classes.textInput} ${classes.errorInput} addressInput` :
                            "textInput"}
                        onChange={handleOrgChange}
                        type="text"
                        placeholder="Address: Optional"
                        name="organization_address"
                    />
                    <p className="errorText">{Orgerrors?.organization_address || ""}</p>
                </span>
            </div>

        </div>
        <hr />
        <div className="rightItem">
            <button className="Createbutton"
                onClick={handleSubmit} >Update Organization</button>
        </div>
    </ >
}

export default App;