import React, { useEffect, useState, useRef } from 'react'
import { Delete } from '../../components/Modal/Delete'
import { BulkDelete } from '../../components/Modal/BulkDelete'
import classes from '../../assets/styles/department.module.css'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";
import { DEPARTMENT } from '../../utils/constants/apiUrl'
import { get } from "../../server";
import Select from 'react-select'
import { loadOrganization } from '../../helpers/apis'
import CreationButton from '../../components/Global Components/CreationButton';
import TableFilter from '../../components/Global Components/TableFilter';
import TableComponent from '../../components/Global Components/DataTable';
import BulkDeleteButton from '../../components/Global Components/BulkDelete';
import DeleteAction from '../../components/Global Components/DeleteAction';
import EditAction from '../../components/Global Components/EditAction';
import { Link, useLocation } from 'react-router-dom';
import showToast from '../../helpers/showToast';
import { Button } from 'reactstrap';
import { BiRefresh } from 'react-icons/bi';

const Department = () => {
    const [loggedUser] = useAtom(loggedInUser);
    const location = useLocation()
    const org = new URLSearchParams(location.search).get('org');

    const [data, setData] = useState()
    const [Data, setDATA] = useState()
    const [query, setQuery] = useState("")
    const [showModal, setShowModal] = useState("close")
    const [select, setSelect] = useState(false)
    const departmentData = useRef()

    const [role, setRole] = useState()
    const [filteredData, setFilteredData] = useState()

    const [organization, setOrganization] = useState()
    const [organizationOptions, setOrganizationOptions] = useState([])

    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        if (org) {
            setOrganization(organizationOptions.find(data => data.value == org))
            setDATA(data?.filter(data => data.organizationId == org))
        }
    }, [org, organizationOptions, data])

    useEffect(() => {
        setRole(loggedUser?.user?.userType)
    }, [loggedUser])

    async function loadDepartment(page, size = perPage) {
        let { status, message, data } = await get(
            DEPARTMENT + "/listing?page=" + page + "&perPage=" + size,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            setTotalRows(data.totalItems)
            setData(data.data)
            setDATA(data.data)
        }
        else if (status === 401)
            showToast(status, message)
    }

    useEffect(() => {
        loadOrganization(loggedUser, setOrganizationOptions)
        loadDepartment(currentPage, perPage)
    }, [currentPage, perPage])

    const handleCheck = ({ selectedRows }) => {
        if (selectedRows.length > 0)
            setSelect(true)
        else if (selectedRows.length === 0)
            setSelect(false)
        departmentData.current = selectedRows
    }

    const columns = [
        {
            name: 'Department Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Department Lead',
            selector: row => row.lead_name,
            sortable: true
        },
        {
            name: "Organization",
            selector: row => row.Organization.name,
            sortable: true,
            omit: role === "super_admin" ? false : true
        },
        {
            name: "Action",
            selector: row => row.action,
            // button: true,
            cell: (row) => <div className={classes.actionIcons}>
                <DeleteAction
                    Data={departmentData}
                    row={row}
                    setShowModal={setShowModal}
                    module={"Departments"}
                />
                <EditAction
                    link={"/department/AddEditDepartment"}
                    id={row.id}
                    module={"Departments"}
                />

            </div>
        },
    ]

    useEffect(() => {
        setFilteredData(Data?.filter(dat => dat.name.toLowerCase().includes(query)))
    }, [query])

    const handleSelectChange = (selectedOptions) => {
        setOrganization(selectedOptions)
        if (selectedOptions === null) {
            setDATA(data)
            if (query)
                setFilteredData(data.filter(dat => dat.name.toLowerCase().includes(query)))
        }
        else {
            const filtered = data.filter(data => data.organizationId === selectedOptions?.value)
            if (query)
                setFilteredData(filtered.filter(dat => dat.name.toLowerCase().includes(query)))
            setDATA(filtered)
        }
    };

    useEffect(() => { loadDepartment(1) }, [showModal])

    return <>
        <div className='card'>
            <div className="card-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <h2 className="card-title">Department View</h2>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Department</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <section className="card-body">
                <CreationButton link={"/department/AddEditDepartment"} module={"Departments"} />

                {select && <BulkDeleteButton
                    setShowModal={setShowModal}
                    module={"Departments"}
                />}
                <Button color="success" className='create' onClick={() => { setCurrentPage(1); setPerPage(10); loadDepartment(1, 10) }}>
                    <BiRefresh className='buttonIcons' />
                    Refresh
                </Button>
                <TableFilter
                    query={query}
                    setQuery={setQuery}
                />
                {role === "super_admin" && <Select
                    options={organizationOptions}
                    value={organization}
                    isClearable={true}
                    onChange={handleSelectChange}
                    placeholder="Select Organization"
                    className="inlineDropdown"
                />}

                <TableComponent
                    columns={columns}
                    Data={Data}
                    filteredData={filteredData}
                    handleCheck={handleCheck}
                    query={query}
                    paginationServer={true}
                    totalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                />

                <Delete showModal={showModal === "delete"}
                    setShowModal={setShowModal}
                    DATA={departmentData.current}
                    type={"Department"}
                    module={DEPARTMENT}
                />
                <BulkDelete showModal={showModal === "Bulk-delete"}
                    setShowModal={setShowModal}
                    DATA={departmentData.current}
                    module={DEPARTMENT}
                />

            </section>
        </div>
    </>
}

export default Department