const Joi = require("joi");
const AddEditEmployeeSchema = Joi.object({
    id: Joi.allow(null),

    job_title: Joi.string().min(4).required().messages({
        "any.required": `Please Enter Job Title`,
        "string.empty": `Please Enter Job Title`,
        "string.min": `Job Title must be minimum 4 characters!`,
        "string.max": `Job Title must be maximum 25 characters!`,
    }),
    position_id: Joi.number().min(1).required().messages({
        "number.empty": `Please Select Position`,
        "any.required": `Please Select Position`,
        "number.min": `Please Select Position`,
    }),
    department_id: Joi.number().min(1).required().messages({
        "number.base": `Please Select Department`,
        "number.empty": `Please Select Department`,
        "number.min": `Please Select Department`,
    }),
    annual_salary: Joi.number().required().messages({
        "number.empty": `Please enter Annual Salary`,
        "number.base": "Please enter Annual Salary",
        "string.empty": "Please enter Annual Salary"
    }),
    // userRole: Joi.number().required().messages({
    //     "string.empty": `Please Select Role`,
    //     "number.base": `Please Select Role`,
    //     "any.required": `Please Select Role`,
    // }),

});

export default AddEditEmployeeSchema;
