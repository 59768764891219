import React, { useEffect, useState } from 'react'
import { useParams, Link } from "react-router-dom";
import { get } from "../../server";
import { OPENING } from '../../utils/constants/apiUrl'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";
import classes from '../../assets/styles/jobOpening.module.css'
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';

const App = () => {
    const [loggedUser] = useAtom(loggedInUser)
    const { openingID } = useParams()
    const [data, setData] = useState()

    async function loadOpening() {
        let { status, data } = await get(
            OPENING + "/id/" + openingID,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        console.log(data)
        if (status === 200)
            setData(data)
    }

    useEffect(() => { if (openingID) loadOpening() }, [])

    return <>

        <Row>
            <Col sm="12">
                <Card>
                    <CardHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">Job Opening Info</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><Link to="/openings">Job Opening</Link></li>
                                        <li className="breadcrumb-item active"> Job Opening info</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </CardHeader>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col xs="12" sm="6" md="6" lg="6" xl="6">
                <Col sm="13" >
                    <Card>
                        <CardHeader>
                            <div className="container-fluid">
                                <div className="row">
                                    <h2 className="card-title">Department Info</h2>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className={classes.content}>

                                <label className={classes.Starter}>Organization</label>
                                <label className={classes.values}>{data?.Organization?.name} </label>

                                <label className={classes.Starter}>Department</label>
                                <label className={classes.values}>{data?.Department?.name}</label>

                                <label className={classes.Starter}>Hiring Manager</label>
                                <label className={classes.values}>{data?.Employee?.User?.firstName} {data?.Employee?.User?.lastName} - {data?.Employee?.Position?.name}</label>

                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="13" >
                    <Card>
                        <CardHeader>
                            <div className="container-fluid">
                                <div className="row">
                                    <h2 className="card-title">Job Info</h2>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className={classes.content}>

                                <label className={classes.Starter}>Total Vacancies</label>
                                <label className={classes.values}>{data?.vacancies}</label>

                                <label className={classes.Starter}>Candidates Applied</label>
                                <label className={classes.values}>{data?.Candidates?.length}</label>

                                <label className={classes.Starter}>Shortlisted Candidates</label>
                                <label className={classes.values}>{data?.Candidates?.filter(data => data.status === "accepted").length}</label>

                                <label className={classes.Starter}>Pending Interviews</label>
                                <label className={classes.values}>{data?.Candidates?.filter(data => data.status === "interview").length}</label>

                                <label className={classes.Starter}>Offered</label>
                                <label className={classes.values}>{data?.Candidates?.filter(data => data.status === "offered").length}</label>

                                <label className={classes.Starter}>Rejected Candidates</label>
                                <label className={classes.values}>{data?.Candidates?.filter(data => data.status === "rejected").length}</label>

                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Col>

            <Col xs="12" sm="6" md="6" lg="6" xl="6">
                <Card style={{ height: "479px" }}>
                    <CardHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <h2 className="card-title">Candidates</h2>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <div className={classes.content}>

                            <label className={classes.Starter}>Opening Name</label>
                            <label className={classes.values}>{data?.name}</label>

                            <label className={classes.Starter}>Employment Type</label>
                            <label className={classes.values}>{data?.employment}</label>

                            <label className={classes.Starter}>Poisition</label>
                            <label className={classes.values}>{data?.Position?.name}</label>

                            <label className={classes.Starter}>Work From</label>
                            <label className={classes.values}>{data?.workFrom}</label>

                            <label className={classes.Starter}>Job Description</label>
                            <label className={classes.values}>{data?.jobDesc}</label>

                            <label className={classes.Starter}>Status</label>
                            <label className={classes.values}>{data?.status}</label>

                        </div>
                    </CardBody>
                </Card>
            </Col>

        </Row>
    </>
}

export default App;