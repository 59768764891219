const customStyles = {
    headCells: {
        style: {
            padding: '0 8px',
            fontSize: '16px',
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: "#27293d",
        },
    },
    cells: {
        style: {
            color: "white",
            backgroundColor: "#27293d",
            padding: '0 8px',
            fontWeight: "bold",
            borderRadius: "0 !important",
        }
    },
    rows: {
        style: {
            fontSize: '15px',
            borderBottom: "0.0625rem solid rgba(34, 42, 66, 0.05)",
        },
    },
    pagination: {
        style: {
            backgroundColor: "#27293d",
            color: "white",
        }
    },
    noData: {
        style: {
            color: "white",
            backgroundColor: "#27293d"
        }
    }
};


export default customStyles