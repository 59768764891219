import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import classes from '../../assets/styles/clockIn.module.css'
import { Modal, ModalBody, Button } from 'reactstrap'
import { get, post } from '../../server'
import { ATTENDANCE } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'
import validateSchema from '../../helpers/ValidateSchema'
import ClockInOutDisputeSchema from '../../utils/SchemasValidation/ClockInOutDisputeSchema'

const emptyObj = {
    dispute_type: "",
    time: "",
    details: "",
    clockInOutId: ""
}

export const Dispute = ({ modal, data, toggleModal, loggedUser }) => {
    const [options, setOptions] = useState()

    const [value, setvalue] = useState()
    const [time, setTime] = useState()
    const [disputeData, setDisputeData] = useState(emptyObj)
    const [disputedData, setDisputedData] = useState()
    const [disabled, setDisabled] = useState(false)

    const [errors, setErrors] = useState({});
    const validate = () => {
        let Errors = validateSchema(disputeData, ClockInOutDisputeSchema)
        setErrors(Errors);
        if (Object.keys(Errors).length) return false;
        return true;
    };

    useEffect(() => {
        if (modal)
            loadDisputes(data)
        if (!modal) {
            setOptions()
            data = {}
            setDisabled(false)
        }
    }, [modal])

    async function loadDisputes(Data) {
        let { status, data } = await get(
            ATTENDANCE + "/clockInOut/disputes/getByDate/" + Data?.date,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status === 200) {
            setDisputedData(data?.ClockInOut_Disputes)
            if (data?.ClockInOut_Disputes.find(dispute => dispute?.disputeType === 'clock_out') && data?.ClockInOut_Disputes.find(dispute => dispute?.disputeType === 'clock_in')) {
                setOptions([])
                setDisabled(true)
            }
            else if (data?.ClockInOut_Disputes.find(dispute => dispute?.disputeType === 'clock_in')) {
                setDisabled(false)
                setOptions([
                    { value: 2, label: "Clock Out", option: 'clock_out' }
                ])
            } else if (data?.ClockInOut_Disputes.find(dispute => dispute?.disputeType === 'clock_out')) {
                setDisabled(false)
                setOptions([
                    { value: 1, label: "Clock In", option: 'clock_in' }
                ])
            } else {
                setDisabled(false)
                setOptions([
                    { value: 1, label: "Clock In", option: 'clock_in' },
                    { value: 2, label: "Clock Out", option: 'clock_out' }
                ])
            }
        }
    }

    // if (!data?.clockOut)
    //     options = [
    //         { value: 1, label: "Clock In", option: 'clock_in' }
    //     ]
    // else
    //     options = [
    //         { value: 1, label: "Clock In", option: 'clock_in' },
    //         { value: 2, label: "Clock Out", option: 'clock_out' }
    //     ]

    useEffect(() => {
        if (data?.id) {
            setDisputeData({ ...disputeData, clockInOutId: data?.id })
        }
    }, [data])

    useEffect(() => {
        if (value)
            setDisputeData({ ...disputeData, dispute_type: value.label })
        if (value?.value == 1) {
            setTime(convertTo24HourFormat(data?.clockIn))
            setDisputeData({ ...disputeData, time: convertTo24HourFormat(data?.clockIn), dispute_type: value?.option })
        }
        else {
            setTime(convertTo24HourFormat(data?.clockOut))
            setDisputeData({ ...disputeData, time: convertTo24HourFormat(data?.clockOut), dispute_type: value?.option })
        }
    }, [value])

    const convertTo24HourFormat = (time) => {
        if (time) {
            const timeComponents = time.split(" ");
            const timeValues = timeComponents[0].split(":");

            let hours = parseInt(timeValues[0], 10);
            const minutes = timeValues[1];
            const seconds = timeValues[2];

            if (timeComponents[1] === "PM" && hours !== 12) {
                hours += 12;
            } else if (timeComponents[1] === "AM" && hours === 12) {
                hours = 0;
            }
            hours = hours.toString().padStart(2, "0");
            return `${hours}:${minutes}:${seconds}`;
        }
    };

    async function handleSubmit() {
        if (!validate()) return
        let { status, message } = await post(
            ATTENDANCE + "/clockInOut/dispute/add",
            disputeData,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status) {
            showToast(status, message)
            toggleModal()
        }
    }

    return <>
        <Modal isOpen={modal} toggle={toggleModal} fade={true}>
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <AiOutlineCloseCircle className='tim-icons' />
                </button>
                <h4 className="title title-up">Open Dispute</h4>
            </div>
            {!disabled ? <div><ModalBody className="text-center">
                {disputedData && <div className={classes.container}>
                    {disputedData?.find(data => data.disputeType === "clock_in") && <label className={classes.labels}>Clock In Dispute</label>}
                    {disputedData?.find(data => data.disputeType === "clock_in") && <label className={classes.labels}>
                        {disputedData?.find(data => data.disputeType === "clock_in")?.status}
                    </label>}

                    {disputedData?.find(data => data.disputeType === "clock_out") && <label className={classes.labels}>Clock Out Dispute</label>}
                    {disputedData?.find(data => data.disputeType === "clock_out") && <label className={classes.labels}>{disputedData?.find(data => data.disputeType === "clock_out")?.status}</label>}
                </div>}
                <div className={classes.container}>
                    <label className={classes.labels}>Date</label>
                    <input
                        value={data?.date.slice(0, 10)}
                        disabled={true}
                        className={classes.Date}
                    />
                    <label className={classes.labels}>Dispute for*</label>
                    <div>
                        <Select
                            options={options}
                            value={value}
                            onChange={setvalue}
                            className={errors?.dispute_type ? "errorInput " : ""}
                        />
                        <p className="errorText">{errors?.dispute_type || ""}</p>
                    </div>
                    <label className={classes.labels}>Your Time</label>
                    <input
                        type="time"
                        className={classes.Date}
                        disabled={true}
                        value={time}
                    />
                    <label className={classes.labels}>Time*</label>
                    <div>
                        <input
                            type="time"
                            disabled={disabled}
                            className={errors?.time ? `errorInput ${classes.Date}` : classes.Date}
                            value={disputeData.time}
                            onChange={e => {
                                setDisputeData({ ...disputeData, time: e.target.value })
                            }}
                        />
                        <p className="errorText">{errors?.time || ""}</p>
                    </div>
                    <label className={classes.labels}>Details*</label>
                    <div>
                        <textarea
                            className={errors?.details ? `errorInput form-control ${classes.Date}` : `form-control ${classes.Date}`}
                            value={disputeData.details}
                            disabled={disabled}
                            onChange={e => {
                                setDisputeData({ ...disputeData, details: e.target.value })
                            }}
                        />
                        <p className="errorText">{errors?.details || ""}</p>
                    </div>
                </div>
            </ModalBody>
                <div className="modal-footer">
                    <Button
                        color="info"
                        data-dismiss="modal"
                        type="button"
                        onClick={handleSubmit}
                        className={classes.SaveButton}
                    >
                        Submit
                    </Button>
                </div>
            </div>
                :
                <div>
                    <ModalBody>
                        <div className={classes.container}>
                            <label className={classes.labels}>Clock In Dispute</label>
                            <label className={classes.labels}>
                                {disputedData?.find(data => data.disputeType === "clock_in")?.status}
                            </label>


                            <label className={classes.labels}>Clock Out Dispute</label>
                            <label className={classes.labels}>{disputedData?.find(data => data.disputeType === "clock_out")?.status}</label>
                        </div>
                    </ModalBody>
                    <div className="modal-footer">
                        <Button
                            color="info"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModal}
                            className={classes.SaveButton}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            }
        </Modal>
    </>

}