import React, { useEffect, useState, useRef } from 'react'
import { CriteriaModal } from './CriteriaModal';
import { Delete } from '../../components/Modal/Delete'
// import { BulkDelete } from '../../components/Modal/BulkDelete'
import classes from '../../assets/styles/criteria.module.css'
import { FaTrashAlt } from 'react-icons/fa'
import { BsPencilSquare } from 'react-icons/bs'
import { AiOutlinePlus } from 'react-icons/ai'
import Select from 'react-select'
import { loadOrganization } from '../../helpers/apis'
import { get } from "../../server";
import { CRITERIA } from '../../utils/constants/apiUrl'
import { loggedInUser } from "../../utils/store";
import { useAtom } from "jotai";

// import BulkDeleteButton from '../../components/Global Components/BulkDelete';
import TableFilter from '../../components/Global Components/TableFilter';
import TableComponent from '../../components/Global Components/DataTable';
import { Card, CardBody, CardHeader, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import { BiRefresh } from 'react-icons/bi';

const Criteria = () => {
    const [loggedUser] = useAtom(loggedInUser)

    const [data, setData] = useState()
    const [Data, setDATA] = useState()
    const [select, setSelect] = useState(false)
    const criteriaData = useRef()
    const [query, setQuery] = useState("")
    const [showModal, setShowModal] = useState('close');
    const [modalType, setModalType] = useState("")
    const [role, setRole] = useState()

    const [filteredData, setFilteredData] = useState()
    const [organization, setOrganization] = useState()
    const [organizationOptions, setOrganizationOptions] = useState([])

    async function loadCriterias() {
        let { status, data } = await get(
            CRITERIA + "/listing",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            setData(data)
            setDATA(data)
        }
    }
    useEffect(() => {
        loadCriterias()
        setRole(loggedUser?.user?.userType)
        loadOrganization(loggedUser, setOrganizationOptions)
    }, [])

    useEffect(() => { loadCriterias() }, [showModal])

    const handleCheck = ({ selectedRows }) => {
        if (selectedRows.length > 0)
            setSelect(true)
        else if (selectedRows.length === 0)
            setSelect(false)
        criteriaData.current = selectedRows
    }

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
            button: true,
            center: true
        },
        {
            name: 'Organization Name',
            omit: role === "super_admin" ? false : true,
            selector: row => row.Organization?.name,
            sortable: true
        },
        {
            name: "Days",
            sortable: true,
            center: true,
            selector: row => row.totalDay
        },
        {
            name: 'Deduction Criteria',
            selector: row => row.percentageDeduction,
            sortable: true,
            center: true,
            cell: (row) => <div>
                {row.percentageDeduction} %
            </div >
        },
        {
            name: "Action",
            button: true,
            cell: (row) => <div className={classes.actionIcons}>
                <FaTrashAlt
                    className={classes.deleteIcon}
                    onClick={() => {
                        setShowModal("delete")
                        criteriaData.current = row
                    }} />
                <BsPencilSquare className={classes.edit}
                    onClick={() => {
                        // setShowModal("Edit")
                        setShowModal("Create")
                        setModalType("edit")
                        criteriaData.current = row.id
                    }}
                />
            </div >
        }
    ]

    useEffect(() => {
        setFilteredData(Data?.filter(dat => dat.totalDay.toString().toLowerCase().includes(query)))
    }, [query])

    const handleSelectChange = (selectedOptions) => {
        setOrganization(selectedOptions)
        if (selectedOptions === null) {
            setDATA(data)
            if (query)
                setFilteredData(data.filter(dat => dat.totalDay.toString().toLowerCase().includes(query)))
        }
        else {
            const filtered = data.filter(data => data.organizationId === selectedOptions?.value)
            if (query)
                setFilteredData(filtered.filter(dat => dat.totalDay.toString().toLowerCase().includes(query)))
            setDATA(filtered)
        }
    };

    return <>

        <Card>
            <CardHeader>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <h2 className="card-title">Late Coming Criteria View</h2>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Late Criteria</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </CardHeader>

            <CardBody>

                <Button color='primary' className="create" onClick={() => { setShowModal("Create"); setModalType("edit") }}>
                    Create <AiOutlinePlus />
                </Button>
                <Button color="success" className='create' onClick={() => loadCriterias()}>
                    <BiRefresh className='buttonIcons' />
                    Refresh
                </Button>
                {/* 
                {select && <BulkDeleteButton
                    setShowModal={setShowModal}
                />} */}

                <TableFilter
                    query={query}
                    setQuery={setQuery}
                />

                {role === "super_admin" && <Select
                    options={organizationOptions}
                    value={organization}
                    isClearable={true}
                    onChange={handleSelectChange}
                    placeholder="Select Organization"
                    className="inlineDropdown"
                />}

                <TableComponent
                    columns={columns}
                    Data={Data}
                    filteredData={filteredData}
                    handleCheck={handleCheck}
                    query={query}
                    allcheck={false}
                />

                <CriteriaModal
                    showModal={showModal === "Create"}
                    modalType={modalType}
                    setShowModal={setShowModal}
                    loggedUser={loggedUser}
                    role={role}
                    id={criteriaData.current}
                />
                <Delete
                    showModal={showModal === "delete"}
                    setShowModal={setShowModal}
                    DATA={criteriaData.current}
                    type={"Criteria Listing"}
                    module={CRITERIA}
                />

                {/* <BulkDelete
                    showModal={showModal === "Bulk-delete"}
                    setShowModal={setShowModal}
                    DATA={criteriaData.current}
                    module={CRITERIA}
                /> */}
            </CardBody>
        </Card>
    </>
}

export default Criteria