import React from 'react'

import { useAtom } from "jotai";
import { loggedInUser } from "../utils/store";

function Home() {
    const [loggedUser] = useAtom(loggedInUser)

    return <>
    </>
}

export default Home