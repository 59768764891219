const Joi = require("joi");
const AddEditDepartmentSchema = Joi.object({
    id: Joi.allow(null),
    name: Joi.string().min(1).max(25).required().messages({
        "string.empty": `Please enter department name`,
    }),

    // leads: Joi.array().min(1).max(5).required().messages({
    //     "array.empty": `Please Select Lead`,
    //     "array.min": `Please select at least one Lead`,
    //     "array.max": `You can select up to 5 Leads`,
    // })

});

export default AddEditDepartmentSchema;
