const Joi = require("joi");
const AddEditCandidateHistorySchema = Joi.object({
    id: Joi.allow(null),

    current_job_title: Joi.string().min(1).max(25).required().messages({
        "string.empty": `Please enter Job Title`,
        "string.min": `username Last name must be minimum 1 characters!`,
        "string.max": `username must be maximum 25 characters!`,
    }),

    current_employer: Joi.string().min(1).max(25).required().messages({
        "string.empty": `Please enter Employer`,
        "string.min": `username Last name must be minimum 1 characters!`,
        "string.max": `username must be maximum 25 characters!`,
    }),

    experience_years: Joi.number().required().messages({
        "number.empty": `Please enter Experience Years`,
    }),
    skillset: Joi.array().min(1).max(5).items(Joi.string().min(4).max(13)).required().messages({
        "array.empty": "Please enter Skill Set",
        "array.min": "Please enter at least one Skill Set",
        "array.max": "You can enter up to 5 Skill Sets",
    }),

});

export default AddEditCandidateHistorySchema;
