import React, { useEffect, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import classes from '../../assets/styles/clockIn.module.css'
import { Modal, ModalBody, Button } from 'reactstrap'
import Select from 'react-select'
import { post } from '../../server'
import { ATTENDANCE } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'

export const DisputeViewer = ({ modal, data, toggleModal, role, loggedUser }) => {
    const [disputeType, setDisputeType] = useState()
    const [dispute, setDispute] = useState()
    const [disputeData, setDisputeData] = useState()

    useEffect(() => {
        if (data) {
            if (data?.ClockInOut_Disputes) {
                const filter = data?.ClockInOut_Disputes?.filter(data => data.status !== "approved")
                const temp = filter?.map(data => ({ value: data.id, label: data.disputeType === "clock_in" ? "Clock In" : "Clock Out", option: data.disputeType }))
                setDisputeType(temp)
            }
        }
    }, [data])

    useEffect(() => {
        if (dispute) {
            const temp = data?.ClockInOut_Disputes?.find(data => data.disputeType === dispute.option)
            setDisputeData(temp)
        }
    }, [dispute])

    const convertTo24HourFormat = (time) => {
        if (time) {
            const timeComponents = time.split(" ");
            const timeValues = timeComponents[0].split(":");

            let hours = parseInt(timeValues[0], 10);
            const minutes = timeValues[1];
            const seconds = timeValues[2];

            if (timeComponents[1] === "PM" && hours !== 12) {
                hours += 12;
            } else if (timeComponents[1] === "AM" && hours === 12) {
                hours = 0;
            }
            hours = hours.toString().padStart(2, "0");
            return `${hours}:${minutes}:${seconds}`;
        }
    };

    async function handleApprove() {
        let { status, message } = await post(
            ATTENDANCE + "/clockInOut/dispute/update",
            { clockInOutId: disputeData.clockInOutId, disputeId: dispute.value },
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status) {
            showToast(status, message)
            if (status === 200) {
                toggleModal()
            }
        }
    }

    return <>
        <Modal isOpen={modal} toggle={toggleModal}>
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <AiOutlineCloseCircle className="tim-icons" />
                </button>
                <h4 className="title title-up">Dispute View</h4>
            </div>
            <ModalBody className="text-center">
                <div className={classes.container}>
                    <label className={classes.labels}>Select Dispute</label>
                    <Select
                        value={dispute}
                        options={disputeType}
                        disabled={true}
                        onChange={setDispute}
                        placeholder="Select Dispute"
                    />
                </div>
                {disputeData && <div className={classes.container}>
                    <label className={classes.labels}>Date</label>
                    <input
                        value={disputeData?.date.slice(0, 10)}
                        disabled={true}
                        className={classes.Date}
                    />
                    <label className={classes.labels}>Dispute for</label>
                    <input
                        type="text"
                        value={disputeData?.disputeType === "clock_in" ? "Clock In" : "Clock Out"}
                        className={classes.Date}
                        disabled={role === "admin"}
                    />
                    <label className={classes.labels}>Clocked Time</label>
                    <input
                        type="text"
                        className={classes.Date}
                        disabled={true}
                        value={disputeData?.time}
                    />
                    <label className={classes.labels}>Fixed Time</label>
                    <input
                        type="text"
                        className={classes.Date}
                        value={disputeData?.correctedTime}
                        disabled={role === "admin"}
                    />
                    <label className={classes.labels}>Details</label>
                    <textarea
                        className={classes.Date}
                        value={disputeData?.details}
                        disabled={role === "admin"}
                    />
                </div>}
            </ModalBody>
            <div className="modal-footer">
                <Button
                    color="danger"
                    type="button"
                    onClick={toggleModal}
                >
                    Close
                </Button>
                {dispute && <Button
                    color="info"
                    data-dismiss="modal"
                    type="button"
                    onClick={handleApprove}
                    style={{ marginLeft: '10px' }}
                >
                    Approve
                </Button>}
            </div>
        </Modal>
    </>

}