import React from 'react'
import jwt from 'jsonwebtoken'
import { useLocation, Navigate, Outlet } from "react-router-dom";
import showToast from '../../helpers/showToast';


const Authenticator = ({ token }) => {
    const location = useLocation();
    if (token) {
        const user = jwt.decode(token)
        const now = Date.now().valueOf() / 1000
        const tokenTime = user.exp
        if (now > tokenTime || !token) {
            showToast("error", "Unauthorized")
            return <Navigate to="/login" state={{ from: location }} replace />
        }
        else
            return <Outlet />
    } else {
        return <Navigate to="/login" state={{ from: location }} replace />
    }
}

export default Authenticator