import React, { useState, useRef, useEffect } from 'react'
import classes from '../../assets/styles/leave.module.css'
import { Preview } from '../../components/Modal/Preview'
import Select from 'react-select'
import { TbSlideshow } from 'react-icons/tb'
import { FaTrashAlt } from 'react-icons/fa'
import { MdCloudUpload } from 'react-icons/md'
import { Link, useNavigate } from "react-router-dom";
import { post } from "../../server";
import ValidateSchema from "../../helpers/ValidateSchema";
import AddEmployeeLeaveSchema from '../../utils/SchemasValidation/AddEmployeeLeaveSchema'
import { LEAVE } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'

import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";
import { Row, Col, Button } from 'reactstrap'

let emptyLeaveForm = {
    reason: "",
    detail: "",
    dates: "",
    num_Days: "",
    fromDate: "",
    toDate: "",
    file: ""
}

const App = () => {
    const [loggedUser] = useAtom(loggedInUser)
    const navigate = useNavigate()
    const today = new Date()
    const disabledDates = { before: today };
    const [dates, setDates] = useState([])
    const [leaveForm, setLeaveForm] = useState(emptyLeaveForm)
    const [errors, setErrors] = useState({});
    const [reasonOptions, setReasonOptions] = useState([
        { value: 1, label: "Sick Leave", option: "sick_leave" },
        { value: 2, label: "Emergency", option: "emergency" },
        { value: 3, label: "Family Emergency", option: "family_emergency" },
        { value: 4, label: "Others", option: "others" },
    ])
    const [reason, setReason] = useState()

    const validateForm = () => {
        let Errors = ValidateSchema(leaveForm, AddEmployeeLeaveSchema)
        setErrors(Errors)
        if (Object.keys(Errors).length) return false;
        return true;
    };
    const fileInputRef = useRef(null);
    const [files, setFiles] = useState([]);
    const previewData = useRef()
    const [showModal, setShowModal] = useState("close")

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFiles([...files, selectedFile]);
    };
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setLeaveForm({ ...leaveForm, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!validateForm()) return;
        const from_date = leaveForm.fromDate.toISOString().slice(0, 10)
        const to_date = leaveForm.toDate.toISOString().slice(0, 10)
        let tempForm = { ...leaveForm }
        delete tempForm.toDate
        delete tempForm.fromDate
        delete tempForm.file //delete later
        tempForm = {
            ...tempForm,
            from_date: from_date,
            to_date: to_date
        }

        let { status, message } = await post(
            LEAVE + "/add",
            tempForm,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        showToast(status, message);
        if (status === 200) {
            navigate("/leaves/my-leaves");
        }
    };

    function LeaveType() {
        const handleLeaveChange = (index, value) => {
            const newDates = [...dates];
            newDates[index].leave = value;
            setDates(newDates);
        };
        return (
            <div className={classes.DateGrid}>
                <div className={classes.panelHeader}>
                    <div>Leave Type</div>
                </div>
                <ul className={classes.panelList}>
                    {dates.map((date, index) => (
                        <li className={classes.panelListli} key={index}>
                            <label className={classes.noMargin}>
                                <input
                                    type="radio"
                                    value="full"
                                    checked={date?.leave === "full"}
                                    // defaultChecked={date.leave === "full"}
                                    name={`leave_type_${index}`}
                                    onChange={() => handleLeaveChange(index, "full")}
                                />
                                <span className={classes.leaveradio}>Full Day</span>
                            </label>
                            <label className={classes.noMargin}>
                                <input
                                    type="radio"
                                    value="half"
                                    checked={date?.leave === "half" ? true : false}
                                    // defaultChecked={date.leave === "half"}
                                    name={`leave_type_${index}`}
                                    onChange={() => handleLeaveChange(index, "half")}
                                />
                                <span className={classes.leaveradio}>Half Day</span>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    const handleDateSelect = (selectedDate) => {
        const existingDates = dates.map(date => date.date);
        const newDates = selectedDate
            .filter(date => !existingDates.includes(date.format()))
            .map(date => ({ date: date.format(), leave: 'full' }));
        const updatedDates = dates.filter(date => selectedDate.some(selected => selected.format() === date.date));

        setDates([...updatedDates, ...newDates]);
        setLeaveForm({ ...leaveForm, dates: [...updatedDates, ...newDates] })

    };

    useEffect(() => {
        if (dates) {
            const alldates = dates?.map((d) => new Date(d.date));
            const startDate = new Date(Math.min(...alldates))
            const endDate = new Date(Math.max(...alldates))
            setLeaveForm({
                ...leaveForm,
                num_Days: dates?.length,
                fromDate: startDate,
                toDate: endDate
            })
        }
    }, [dates])

    return <>
        <Row>
            <Col sm="12">
                <div className='card'>
                    <div className="card-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">Apply for Leaves</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" ><Link to="/leaves/my-leaves">Leaves</Link></li>
                                        <li className="breadcrumb-item active">Dashboard</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>

            <Col sm="6">
                <div className='card'>
                    <div className='card-body'>
                        <div className={classes.LeaveFormGrid}>

                            <div className={classes.leaveGrid}>
                                <span className="LeaveStart">Reason*</span>
                                <Select
                                    options={reasonOptions}
                                    value={reason}
                                    onChange={(e) => {
                                        setLeaveForm({ ...leaveForm, reason: e.option })
                                    }}
                                    name="reason_id"
                                    className={errors?.reason ? `${classes.width} errorInput` : classes.width}
                                />
                                <p className="errorText">{errors?.reason || ""}</p>
                            </div>

                            <div className={classes.leaveGrid}>
                                <span className="LeaveStart">Details*</span>
                                <span>
                                    <textarea
                                        type="text"
                                        value={leaveForm.detail}
                                        name="detail"
                                        className={errors?.detail ? `form-control ${classes.Reason} ${classes.errorInput}` : `form-control ${classes.Reason}`}
                                        onChange={handleChange}
                                    />
                                    <p className="errorText">{errors?.detail || ""}</p>
                                </span>
                            </div>

                            <div className={classes.leaveGrid}>
                                <span className="LeaveStart">Number of Days:</span>
                                <span className="LeaveStart">
                                    {dates.length}
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </Col>

            <Col sm="6">
                <div className='card' style={{ height: '388px' }}>
                    <div className="card-header">
                        <div className="container-fluid">
                            <div className="row">
                                <h2 className="card-title">Dates</h2>
                            </div>
                        </div>
                    </div>

                    <div className='card-body'>
                        <div style={{ textAlign: '-webkit-center' }}>
                            <Calendar
                                value={dates.date}
                                // format="DD-MM-YYYY"
                                multiple={true}
                                sort
                                plugins={[
                                    <DatePanel />,
                                    <LeaveType />
                                ]}
                                clickUnselectsDay={false}
                                minDate={disabledDates}
                                onChange={handleDateSelect}
                                inputClass={errors?.fromDate ? `${classes.Dates} ${classes.errorInput}` : classes.Dates}
                            />
                            <p className="errorText">{errors?.fromDate || ""}</p>
                        </div>
                    </div>
                </div>
            </Col>

            <Col sm="4">
                <div className='card'>
                    <div className="card-header">
                        <div className="container-fluid">
                            <div className="row">
                                <h2 className="card-title">Upload Files</h2>
                            </div>
                        </div>
                    </div>

                    <div className='card-body'>
                        <div className='alignCenter'>
                            <div className={classes.uploadBackground}>
                                <MdCloudUpload className={classes.uploadIcon} />
                            </div>
                            <br />
                            <input type="file"
                                multiple
                                onChange={handleFileChange}
                                accept="image/*, application/pdf"
                                className={classes.fileUploader}
                                ref={fileInputRef}
                            />
                            <Button
                                color={"default"}
                                className={"default"}
                                onClick={() => handleClick()}
                            >
                                Select Files
                            </Button>
                        </div>
                    </div>
                </div>
            </Col>

            <Col sm="8">
                <div className='card' style={{ height: '309px' }}>
                    <div className="card-header">
                        <div className="container-fluid">
                            <div className="row">
                                <h2 className="card-title">Your Files</h2>
                            </div>
                        </div>
                    </div>

                    {files.length > 0 && <div className='card-body'>
                        <div>
                            {files.map((file, index) => {
                                return (
                                    <div style={{ width: '100px' }}>
                                        {file.type.startsWith('image/') ? (
                                            <div>
                                                <img src={URL.createObjectURL(file)} alt="File preview" height="120px" width="100px" />
                                            </div>
                                        ) : (
                                            <iframe src={URL.createObjectURL(file)} title="File preview" height="100px" width="100px" />
                                        )}
                                        <div className={classes.previewIcons}>

                                            <TbSlideshow
                                                className={classes.previewIcon}
                                                onClick={() => {
                                                    setShowModal("preview")
                                                    previewData.current = file
                                                }}
                                            />
                                            <FaTrashAlt
                                                onClick={(ee) => {
                                                    let newFiles = [...files]
                                                    newFiles.splice(index, 1)
                                                    setFiles(newFiles)
                                                }}
                                                className={classes.deleteIcon}
                                            />
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                    }
                </div>
            </Col>

            <Col sm="12">
                <div className='card'>
                    <div className='card-body'>
                        <div className="rightItem">
                            <Button
                                color='info'
                                className="Createbutton"
                                // type="submit"
                                onClick={handleSubmit}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>

            </Col>
            <Preview
                showModal={showModal === "preview"}
                setShowModal={setShowModal}
                file={previewData.current}
            />
            {/* <div className="card-body">
                <div className={classes.LeaveFormGrid}>

                    {files.length > 0 && <div className={classes.leaveGrid}>
                        <span className={classes.LeaveStart}>Files</span>
                        <span>
                            {files.map((names, index) => <div key={index} className={classes.fileData}>
                                <span className={classes.fileName}>{names?.name}</span>

                                <TbSlideshow
                                    className={classes.slideShowIcon}
                                    onClick={() => {
                                        setShowModal("preview")
                                        previewData.current = names
                                    }}
                                />
                                <FaTrashAlt
                                    onClick={(ee) => {
                                        let newFiles = [...files]
                                        newFiles.splice(index, 1)
                                        setFiles(newFiles)
                                    }}
                                    className={classes.slideShowIcon}
                                />
                            </div>)}
                        </span>
                    </div>}
                    

                </div>
                <br />
                <hr />

            </div > */}
        </Row>
    </ >
}

export default App;