import React, { useState, useEffect } from 'react'
import defaultPicture from '../../assets/images/defaultProfile.png'
import classes from '../../assets/styles/organization.module.css'
import { WithContext as ReactTags } from 'react-tag-input';
import { BiImageAdd } from 'react-icons/bi'
import { FaTrashAlt, FaEyeSlash, FaEye } from 'react-icons/fa'
import { useParams, useNavigate, Link } from "react-router-dom";
import { get, post } from "../../server";
import ValidateSchema from "../../helpers/ValidateSchema";
import AddEditOrganizationSchema from '../../utils/SchemasValidation/AddEditOrganizationSchema'
import AddEditUserSchema from '../../utils/SchemasValidation/AddEditUserSchema'
import { ORGANIZATION } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'

import { Stepper, Step } from "react-form-stepper";
import StepWizard from "react-step-wizard";
import { Row, Col, Button } from "reactstrap";
import { useAtom } from 'jotai';
import { loggedInUser } from '../../utils/store';

const KeyCodes = {
    enter: 13,
    tab: 9
};
const delimiters = [KeyCodes.enter, KeyCodes.tab];

const emptyUser = {

};
const emptyOrg = {
    username: "",
    password: "",
    first_name: "",
    last_name: "",
    email: "",
    avatar: "",
    organization_name: "",
    organization_Email: [],
    organization_Phone_no: [],
    organization_address: "",
    organization_city: "",
    organization_avatar: ""
};

const ActionButtons = (props) => {
    const handleBack = () => {
        props.previousStep();
    };

    const handleNext = () => {
        props.nextStep();
    };

    const handleFinish = () => {
        props.lastStep();
    };

    return (
        <div>
            <Row>
                {props.currentStep > 1 && (
                    <Col>
                        <Button
                            color='primary'
                            onClick={handleBack}
                            className="backButton"
                        >Back</Button>
                    </Col>
                )}
                <Col>
                    {props.currentStep < props.totalSteps && (
                        <Button
                            color='primary'
                            onClick={handleNext}
                            className="nextButton"
                        >Next</Button>
                    )}
                    {props.currentStep === props.totalSteps && (
                        <Button
                            color='primary'
                            onClick={handleFinish}
                            className="nextButton"
                        >{props.organizationID ? "Update" : "Create"}</Button>
                    )}
                </Col>
            </Row>
        </div>
    );
};

const OrganizationProfile = (props) => {
    const [tagIndex, setTagIndex] = useState()
    const [Orgerrors, setOrgErrors] = useState({});
    const OrgValidate = () => {
        let orgErrors = ValidateSchema(props.organization, AddEditOrganizationSchema)
        setOrgErrors(orgErrors);
        if (Object.keys(orgErrors).length) return false;
        else {
            props.nextStep()
        }
        return true;
    };

    const handleOrgChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        props.setOrganization({ ...props.organization, [name]: value });
    };

    const handleAddition = tag => {
        props.setPhonoNo([...props.phoneNo, tag])
        props.setOrganization({
            ...props.organization,
            organization_Phone_no: [...props.organization.organization_Phone_no, tag.text]
        })
    };

    const handleDelete = (i) => {
        props.setPhonoNo(props.phoneNo.filter((tag, index) => index !== i));
        const numbers = props.phoneNo.map(obj => obj.text).filter((tag, index) => index !== i)
        props.setOrganization({
            ...props.organization,
            organization_Phone_no: numbers
        })
    };

    const handleEdit = (inputValue,) => {
        const tags = [...props.phoneNo];
        if (tagIndex !== -1) {
            tags[tagIndex] = { id: inputValue, text: inputValue };
            if (delimiters) {
                props.setPhonoNo(tags);
                const textValues = tags.map(item => item.text);
                props.setOrganization({
                    ...props.organization,
                    organization_Phone_no: textValues
                })
            }
        }
    };

    const handleAdditionEmail = tag => {
        props.setEmail([...props.email, tag])
        props.setOrganization({
            ...props.organization,
            organization_Email: [...props.organization.organization_Email, tag.text]
        })
    };

    const handleDeleteEmail = (i) => {
        props.setEmail(props.email.filter((tag, index) => index !== i));
        const emails = props.email.map(obj => obj.text).filter((tag, index) => index !== i)
        props.setOrganization({
            ...props.organization,
            organization_Email: emails
        })
    };

    const handleEditEmail = (inputValue,) => {
        const tags = [...props.email];
        if (tagIndex !== -1) {
            tags[tagIndex] = { id: inputValue, text: inputValue };
            props.setEmail(tags);
            const textValues = tags.map(item => item.text);
            props.setOrganization({
                ...props.organization,
                organization_Email: textValues
            })
        }
    };

    return <>
        <div className={classes.centeredItem}>
            <label className={classes.relativeItem}>
                <img src={props.srcO ?
                    props.srcO : props.organization.organization_avatar ?
                        props.organization.organization_avatar : defaultPicture
                }
                    alt=""
                    className={classes.avatar}
                />

                <label className={classes.icons}>
                    <label className={classes.ImageDiv2}>
                        <input type='file'
                            className={classes.noDisplay}
                            onChange={props.handleFileChangeO}
                            accept='image/*'
                        />
                        <BiImageAdd
                            className={classes.imageIcon}
                        />
                        {(props.organization.avatar || props.srcO) ?
                            <FaTrashAlt onClick={(e) => {
                                e.preventDefault()
                                props.selectFileO(null)
                                props.setFileNameO(null)
                                props.setImageO(null)
                            }} className={classes.deleteIcon}
                            /> : null}

                    </label>
                </label>
            </label>
        </div>

        <div className={classes.OrganizationView}>

            <div>
                <span className='Starter'>Organization Name*</span>
                <input
                    value={props.organization.organization_name}
                    className={Orgerrors?.organization_name ? "form-control textInput errorInput " : " form-control textInput"}
                    onChange={handleOrgChange}
                    type="text"
                    placeholder="Twitter"
                    name="organization_name"
                    autoComplete="off"
                />
                <p className="errorText">{Orgerrors?.organization_name || ""}</p>
            </div>

            <div>
                <span className='Starter'> Phone Number*</span>
                <ReactTags
                    tags={props.phoneNo}
                    delimiters={delimiters}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    handleTagClick={setTagIndex}
                    handleInputChange={handleEdit}
                    inputFieldPosition="inline"
                    editable
                    // onTagUpdate={(editIndex, tag) => console.log(editIndex, tag)}
                    allowDragDrop={false}
                    placeholder="Enter Numbers"
                    classNames={
                        {
                            tags: {
                                "rti--container": true,
                                "rti--container errorInput": Orgerrors?.phoneNo
                            },
                            tagInput: "rti--input",
                            tagInputField: classes.inputField,
                            tag: "rti--tag",
                            editTagInputField: classes.inputField
                        }
                    }
                    inputFiled
                />
                <p className="errorText">{Orgerrors?.phoneNo || ""}</p>
            </div>

            <div>
                <span className='Starter'>Email*</span>
                <ReactTags
                    tags={props.email}
                    delimiters={delimiters}
                    handleDelete={handleDeleteEmail}
                    handleAddition={handleAdditionEmail}
                    handleTagClick={setTagIndex}
                    handleInputChange={handleEditEmail}
                    inputFieldPosition="inline"
                    editable
                    allowDragDrop={false}
                    placeholder="Enter Emails"
                    classNames={
                        {
                            tags: {
                                "rti--container": true,
                                "rti--container errorInput": Orgerrors?.email
                            },
                            // tags: "rti--container",
                            tagInput: "rti--input",
                            tagInputField: classes.inputField,
                            tag: "rti--tag",
                            editTagInputField: classes.inputField
                        }
                    }
                />
                <p className="errorText">{Orgerrors?.email || ""}</p>
            </div>

            <div>
                <span className='Starter'>City</span>
                <input
                    value={props.organization.organization_city}
                    className={Orgerrors?.organization_city ? "form-control textInput errorInput " : " form-control textInput"}
                    onChange={handleOrgChange}
                    type="text"
                    placeholder="City"
                    name="organization_city"
                    autoComplete='off'
                />
                <p className="errorText">{Orgerrors?.organization_city || ""}</p>
            </div>

            <div>
                <span className='Starter'>Address</span>
                <textarea
                    value={props.organization.organization_address}
                    className={Orgerrors?.organization_address ? `form-control textareaH errorInput ` : "form-control textareaH "}
                    onChange={handleOrgChange}
                    type="text"
                    placeholder="Address: Optional"
                    name="organization_address"
                />
                <p className="errorText">{Orgerrors?.organization_address || ""}</p>
            </div>

        </div>

        <ActionButtons {...props} nextStep={OrgValidate} />
    </>
}

const UserProfile = (props) => {
    const [Usererrors, setUserErrors] = useState({});

    const validate = () => {
        let userErrors = ValidateSchema(props.organization, AddEditUserSchema)
        setUserErrors(userErrors);
        if (Object.keys(userErrors).length) return false;
        else {
            props.lastStep();
            props.completeCallback();
        }
        return true;
    };

    const [type, setType] = useState('password')

    const handleToggle = () => {
        if (type === 'password') {
            setType('text')

        }
        else {
            setType('password')
        }
    }


    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        props.setOrganization({ ...props.organization, [name]: value });
    };

    return <>
        <div className={classes.centeredItem}>
            <label className={classes.relativeItem}>
                <img
                    src={props.src ?
                        props.src : props.organization.avatar ?
                            props.organization.avatar : defaultPicture
                    }
                    alt=""
                    className={classes.avatar}
                />
                <label className={classes.ImageDiv2}>
                    <label className={classes.icons}>
                        <label>
                            <input type='file'
                                className={classes.noDisplay}
                                onChange={props.handleFileChange}
                                accept='image/*'
                            />
                            <BiImageAdd
                                className={classes.imageIcon}
                            />
                        </label>
                        {(props.organization.avatar || props.src) ?
                            <FaTrashAlt
                                onClick={(e) => {
                                    e.preventDefault()
                                    props.selectFile(null)
                                    props.setFileName(null)
                                    props.setImage(null)
                                }}
                                className={classes.deleteIcon}
                            /> : null}
                    </label>
                </label>
            </label>
        </div>

        <div className={classes.OrganizationView}>
            <div>

                <label className='Starter'>First Name*</label>
                <input
                    value={props.organization.first_name}
                    className={Usererrors?.first_name ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={handleChange}
                    type="text"
                    placeholder="First Name"
                    name='first_name'
                />
                <p className="errorText">{Usererrors?.first_name || ""}</p>

            </div>

            <div>
                <label className='Starter'>Last Name*</label>
                <input
                    value={props.organization.last_name}
                    className={Usererrors?.last_name ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={handleChange}
                    type="text"
                    placeholder="Last Name"
                    name='last_name'
                />
                <p className="errorText">{Usererrors?.last_name || ""}</p>
            </div>

            <div>
                <label className='Starter'>Phone Number*</label>
                <input
                    value={props.organization.phone_no}
                    className={Usererrors?.phone_no ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={handleChange}
                    type="tel"
                    placeholder="Phone Number"
                    name='phone_no'
                />
                <p className="errorText">{Usererrors?.phone_no || ""}</p>
            </div>

            <div>
                <label className='Starter'>Email*</label>
                <input
                    value={props.organization.email}
                    className={Usererrors?.email ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={handleChange}
                    type="email"
                    placeholder="Email Address"
                    name='email'
                    autoComplete='off'
                />
                <p className="errorText">{Usererrors?.email || ""}</p>
            </div>

            <div>
                <label className='Starter'>Username*</label>
                <input
                    value={props.organization.username}
                    className={Usererrors?.username ?
                        "form-control textInput errorInput " :
                        "form-control textInput"}
                    onChange={handleChange}
                    type="text"
                    placeholder="Username"
                    name='username'
                    autoComplete='off'
                />
                <p className="errorText">{Usererrors?.username || ""}</p>
            </div>

            <div className={classes.passwordLabel}>
                <label className='Starter'>Password*    </label>
                <input
                    value={props.organization.password}
                    className={Usererrors?.password ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={handleChange}
                    type="text"
                    placeholder="Password"
                    name='password'
                />
                <label className={classes.eye}
                    onClick={handleToggle}>
                    {type === "password" ? <FaEye /> : <FaEyeSlash />}
                </label>
                <p className="errorText">{Usererrors?.password || ""}</p>
            </div>

        </div>
        <ActionButtons {...props} organizationID={props.organizationID} lastStep={validate} />
    </>
}

const App = () => {
    const [loggedUser] = useAtom(loggedInUser)
    const [email, setEmail] = useState([])
    const [phoneNo, setPhonoNo] = useState([])

    const { organizationID } = useParams()
    const [user, setUser] = useState(emptyUser)
    const [organization, setOrganization] = useState(emptyOrg)

    const navigate = useNavigate();

    const [src, selectFile] = useState("    ")
    const [fileName, setFileName] = useState()
    const [image, setImage] = useState()

    const handleFileChange = e => {
        setFileName(e.target.files[0].name);
        selectFile(URL.createObjectURL(e.target.files[0]))
        setImage(e.target.files[0])
    }

    const [srcO, selectFileO] = useState("")
    const [fileNameO, setFileNameO] = useState()
    const [imageO, setImageO] = useState()

    const handleFileChangeO = e => {
        setFileNameO(e.target.files[0].name);
        selectFileO(URL.createObjectURL(e.target.files[0]))
        setImageO(e.target.files[0])
    }

    const loadOrganization = async () => {
        let { status, message, data } = await get(
            ORGANIZATION + `/${organizationID}`,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            const {
                name,
                avatar,
                phoneNo,
                email,
                address,
                city
            } = data;

            const {
                firstName,
                lastName,
                username
            } = data.User

            const emailData = email.split(",")
            const OrgEmails = emailData.map((tag) => ({ id: tag, text: tag }))

            const numbData = phoneNo.split(",")
            const OrgNumbs = numbData.map((tag) => ({ id: tag, text: tag }))

            setEmail(OrgEmails)
            setPhonoNo(OrgNumbs)
            setOrganization({
                first_name: firstName,
                last_name: lastName,
                username,
                email: data.User.email,
                phone_no: data.User.phoneNo,
                avatar: data.User.avatar,
                organization_name: name,
                organization_avatar: avatar,
                organization_Phone_no: [phoneNo],
                organization_Email: [email],
                organization_address: address,
                organization_city: city
            });
        } else {
            showToast(status, message);
        }
    };

    useEffect(() => {
        if (organizationID) loadOrganization();
    }, [])

    const handleSubmit = async (e) => {
        const emailString = organization.organization_Email.join(", ")
        const numberString = organization.organization_Phone_no.join(", ")
        let tempOrg = {
            ...organization,
            organization_email: emailString,
            organization_phone_no: numberString
        }
        delete tempOrg.organization_Email
        delete tempOrg.organization_Phone_no
        delete tempOrg.avatar       //remove when file handling is added
        delete tempOrg.organization_avatar      //remove when file handling is added
        let { status, message } = await post(
            ORGANIZATION + "/addOrUpdate" + (organizationID ? `/${organizationID}` : ""),
            tempOrg,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            navigate("/organization");
        }
        showToast(status, message);
    };

    const [activeStep, setActiveStep] = useState(0);
    const [stepWizard, setStepWizard] = useState(null);
    const assignStepWizard = (instance) => {
        setStepWizard(instance);
    };

    const assignUser = (val) => {
        setUser((user) => ({
            ...user,
            ...val
        }));
    };

    const handleStepChange = (e) => {
        setActiveStep(e.activeStep - 1);
    };

    function handleStepClick(step) {
        setActiveStep(step);
        stepWizard.goToStep(step + 1);
    }

    return <>
        <Row>
            <Col sm="12">
                <div className='card'>
                    <div className="card-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">{organizationID ? "Update " : "Create new "}Organization</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" ><Link to="/organization">Organization</Link></li>
                                        <li className="breadcrumb-item active">Organization Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>

            <Col sm="12">
                <div className='card'>
                    <div className="card-body">
                        <Stepper
                            activeStep={activeStep}
                            connectorStyleConfig={
                                {
                                    activeColor: '#ba54f5',
                                    completedColor: "#24415e",
                                    size: 2,

                                }
                            }
                            styleConfig={{
                                activeBgColor: '#1d8cf8',
                                completedBgColor: "#ba54f5",
                                labelFontSize: "20px",
                                size: "2.5em"
                            }}
                        >
                            <Step label="Organization Details" children={" "} id="0" onClick={() => handleStepClick(0)} />
                            <Step label="Contact Details" children=" " id="1" onClick={() => handleStepClick(1)} disabled={organizationID && false} />
                        </Stepper>
                        <StepWizard instance={assignStepWizard} onStepChange={handleStepChange}>
                            <OrganizationProfile
                                organization={organization}
                                setOrganization={setOrganization}
                                phoneNo={phoneNo}
                                setPhonoNo={setPhonoNo}
                                email={email}
                                setEmail={setEmail}
                                srcO={srcO}
                                handleFileChangeO={handleFileChangeO}
                                selectFileO={selectFileO}
                                setFileNameO={setFileNameO}
                                setImageO={setImageO}
                            />
                            <UserProfile
                                organization={organization}
                                setOrganization={setOrganization}
                                src={src}
                                handleFileChange={handleFileChange}
                                selectFile={selectFile}
                                setFileName={setFileName}
                                setImage={setImage}
                                organizationID={organizationID}
                                completeCallback={handleSubmit}
                            />
                        </StepWizard>
                    </div>
                </div>
            </Col>
        </Row>
    </>
}

export default App; 