import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import classes from '../../assets/styles/criteria.module.css'
import { AiOutlineClose } from 'react-icons/ai'
import { get, post } from "../../server";
import { CRITERIA } from '../../utils/constants/apiUrl'
import ValidateSchema from "../../helpers/ValidateSchema";
import AddEditCriteriaSchema from '../../utils/SchemasValidation/AddEditCriteriaSchema'
import { loadOrganization } from '../../helpers/apis'
import { Modal, ModalBody, Button } from 'reactstrap'
import showToast from '../../helpers/showToast';

let emptyCriteria = {
    total_day: 0,
    percentage_deduction: 0,
    organization_id: "",
};

export const CriteriaModal = ({ showModal, setShowModal, modalType, id, loggedUser, role }) => {


    const [critera, setCritera] = useState(emptyCriteria)
    const [organization, setOrganization] = useState()
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let Errors = ValidateSchema(critera, AddEditCriteriaSchema)
        setErrors(Errors);
        if (Object.keys(Errors).length) return false;
        return true;
    };

    const [organizationOptions, setOrganizationOptions] = useState()

    useEffect(() => {
        loadOrganization(loggedUser, setOrganizationOptions)
    }, [])

    useEffect(() => { if (id && modalType === "edit") loadCriteria() }, [id, modalType])

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setCritera({ ...critera, [name]: value });
    };

    async function loadCriteria() {
        let { status, data } = await get(
            CRITERIA + "/id/" + id,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status === 200) {
            setCritera({
                total_day: data.totalDay,
                percentage_deduction: data.percentageDeduction,
                organization_id: data.organizationId,
            })
            setOrganization({ value: data.Organization.id, label: data.Organization.name })
        }
    }

    const handleSubmit = async (e) => {
        if (!validateForm()) return
        let { status, message } = await post(
            CRITERIA + "/addorUpdate" + (id ? `/${id}` : ""),
            critera,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status) {
            showToast(status, message)
            if (status === 200)
                setShowModal("close")
        }
    };

    return <>
        <Modal isOpen={showModal}>
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setShowModal('close')}
                >
                    <AiOutlineClose className='tim-icons' />
                </button>
                <h4 className="title title-up">{id ? "Edit" : "Create"} Criteria</h4>
            </div>
            <ModalBody className="text-center">
                <div className={classes.ModalGrid}>
                    {role === "super_admin" &&
                        <span className={classes.ModalStarts}>Organization</span>
                    }
                    {role === "super_admin" &&
                        <span>
                            <Select
                                options={organizationOptions}
                                value={organization}
                                className={errors?.organization_name ? "errorInput" : ""}
                                onChange={e => {
                                    setCritera({ ...critera, organization_id: e.value })
                                }}
                            />
                            <p className="errorText">{errors?.organization_name || ""}</p>
                        </span>
                    }

                    <span className={classes.ModalStarts}>
                        Days
                    </span >
                    <span>
                        <input
                            type="number"
                            name="total_day"
                            value={critera.total_day}
                            className={errors?.total_day ? `${classes.CriteriaInput} ${classes.errorInput}` : classes.CriteriaInput}
                            onChange={handleChange}
                        />
                        <p className="errorText">{errors?.total_day || ""}</p>
                    </span>
                    <span className={classes.ModalStarts}>
                        Criteria Percentage %
                    </span >
                    <span>
                        <input
                            type="number"
                            name="percentage_deduction"
                            value={critera.percentage_deduction}
                            className={errors?.total_day ? `${classes.CriteriaInput} ${classes.errorInput}` : classes.CriteriaInput}
                            onChange={handleChange}
                        />
                        <p className="errorText">{errors?.percentage_deduction || ""}</p>
                    </span>
                </div>
            </ModalBody>
            <div className="modal-footer">
                <Button
                    color="info"
                    data-dismiss="modal"
                    type="button"
                    onClick={handleSubmit}
                    className={classes.SaveButton}
                >
                    Save
                </Button>
            </div>
        </Modal >
    </>

}