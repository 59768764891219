import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import classes from '../../assets/styles/clockIn.module.css'
import { Modal, ModalBody, Button } from 'reactstrap'
import { post } from '../../server'
import { ATTENDANCE } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'


export const OvertimeViewer = ({ modal, toggleModal, loggedUser, data }) => {
    function closeModal() {
        toggleModal()
    }
    async function handleSubmit(stat) {
        const { status, message } = await post(
            ATTENDANCE + "/clockInOut/overtime/update/" + data?.id,
            { status: stat },
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status) {
            showToast(status, message)
            if (status === 200) {
                closeModal()
            }
        }
    }

    function getMonthFromWeekNumber(weekNumber, year) {
        const date = new Date(year, 0, 1);
        const firstDayOfYear = date.getDay() || 7;
        const daysToAdd = (weekNumber - 1) * 7 + (8 - firstDayOfYear);
        date.setDate(daysToAdd);
        const month = date.toLocaleString('default', { month: 'long' });
        return month;
    }
    return <>
        <Modal isOpen={modal} toggle={toggleModal}>
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <AiOutlineCloseCircle className="tim-icons" />
                </button>
                <h4 className="title title-up">Overtime Request</h4>
            </div>
            <ModalBody className="text-center">
                <div className={classes.container}>
                    <label className={classes.labels}>Week</label>
                    <input
                        value={data?.weekNumber}
                        disabled={true}
                        className={classes.Date}
                    />
                    <label className={classes.labels}>Month</label>
                    <input
                        value={getMonthFromWeekNumber(data?.weekNumber, data?.year) + ", " + data?.year}
                        disabled={true}
                        className={classes.Date}
                    />
                    <label className={classes.labels}>Total Hours</label>
                    <input
                        value={data?.totalHours}
                        disabled={true}
                        className={classes.Date}
                    />
                    <label className={classes.labels}>Details</label>
                    <textarea
                        value={data?.detail}
                        disabled={true}
                        className={classes.Date}
                        placeholder='Details'
                    />
                    <label className={classes.labels}>Status</label>
                    <input
                        value={data?.status}
                        disabled={true}
                        className={classes.Date}
                    />
                </div>
            </ModalBody>
            <div className="modal-footer">
                <Button
                    color="danger"
                    type="button"
                    onClick={() => handleSubmit("rejected")}
                >
                    Reject
                </Button>
                <Button
                    color="info"
                    type="button"
                    onClick={() => handleSubmit("accepted")}
                    style={{ marginLeft: '10px' }}
                >
                    Approve
                </Button>
            </div>
        </Modal>
    </>
}