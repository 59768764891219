
import { RiChatSettingsLine } from 'react-icons/ri'
import { SlCalender } from 'react-icons/sl'
import {
  FaTachometerAlt, FaNetworkWired, FaProjectDiagram, FaUserAlt,
  FaUserCheck, FaRegUser, FaUsers, FaDoorOpen
} from 'react-icons/fa'
import { GoOrganization } from 'react-icons/go'
import { BsBarChartSteps } from 'react-icons/bs'
import { BiBarChartAlt2, BiLineChartDown, BiCircle } from 'react-icons/bi'
import { MdWork, MdPunchClock } from 'react-icons/md'
import { TbReport } from 'react-icons/tb'
var routes = [
  {
    path: "/home",
    name: "Dashboard",
    icon: FaTachometerAlt,
    permission: {
      role: ["super_admin", "organization", "employee"]
    }
  },
  {
    path: "/organization",
    name: "Organization",
    icon: GoOrganization,
    permission: {
      role: ["super_admin"],
    },
  },
  {
    path: "/department",
    name: "Departments",
    icon: FaNetworkWired,
    permission: {
      role: ["super_admin", "organization"]
    },
    shortName: "departments"
  },
  {
    path: "/project",
    name: "Projects",
    icon: FaProjectDiagram,
    permission: {
      role: ["super_admin", "organization"]
    },
    shortName: "projects"
  },
  {
    name: "Employees",
    icon: FaUserAlt,
    collapse: true,
    state: "pagesCollapse",
    permission: {
      role: ["super_admin", "organization"]
    },
    shortName: "employees",
    views: [
      {
        path: "/employee",
        name: "Employees",
        icon: FaUsers,
        permission: {
          role: ["super_admin", "organization"]
        },
        shortName: "employees"
      },
      {
        path: "/employee/attendance",
        name: "Attendance",
        icon: FaUserCheck,
        permission: {
          role: ["super_admin", "organization"]
        },
        shortName: "attendance"
      }
    ]
  },
  {
    name: "Leaves",
    icon: SlCalender,
    collapse: true,
    state: "leavesCollapse",
    permission: {
      role: ["super_admin", "organization", "employee"]
    },
    views: [
      {
        path: "/leaves",
        name: "All Leaves",
        icon: BiCircle,
        permission: {
          role: ["super_admin", "organization"]
        },
        shortName: "leaves"
      },
      {
        path: "/leaves/my-leaves",
        name: "My Leaves",
        icon: BiCircle,
        permission: {
          role: ["employee"]
        }
      },
      {
        path: "/leaves/apply",
        name: "Apply for Leave",
        icon: BiCircle,
        permission: {
          role: ["employee"]
        }
      }
    ]
  },
  {
    path: "/checkin-and-checkout",
    name: "Clock In & Out",
    icon: FaDoorOpen,
    permission: {
      role: ["employee"]
    }
  },
  {
    path: "/checkin-and-checkout/my-report",
    name: "Weekly Reports",
    icon: TbReport,
    permission: {
      role: ["employee"]
    }
  },
  {
    name: "Clock In & Out",
    icon: FaDoorOpen,
    collapse: true,
    state: "clockCollapse",
    permission: {
      role: ["super_admin", "organization"]
    },
    views: [
      {
        path: "/checkin-and-checkout/panel",
        name: "Panel",
        icon: MdPunchClock,
        permission: {
          role: ["super_admin", "organization"]
        },
      },
      {
        path: "/checkin-and-checkout/report",
        name: "Reports",
        icon: TbReport,
        permission: {
          role: ["super_admin", "organization"]
        }
      },
    ]
  },
  {
    path: "/positions",
    name: "Positions",
    icon: BsBarChartSteps,
    permission: {
      role: ["super_admin", "organization"]
    }
  },
  {
    path: "/roles-permissions",
    name: "Roles & Permissions",
    icon: BiBarChartAlt2,
    permission: {
      role: ["super_admin", "organization"]
    }
  },
  {
    path: "/openings",
    name: "Job Openings",
    icon: MdWork,
    permission: {
      role: ["super_admin", "organization"]
    },
    shortName: "openings"
  },
  {
    path: "/candidate",
    name: "Candidates",
    icon: FaRegUser,
    permission: {
      role: ["super_admin", "organization"]
    },
    shortName: "candidates"
  },
  {
    path: "/late-coming-criteria",
    name: "Late Comings Criteria",
    icon: BiLineChartDown,
    permission: {
      role: ["super_admin", "organization"]
    }
  },
  {
    path: "/settings/leave-settings",
    name: "Leave Settings Criteria",
    icon: RiChatSettingsLine,
    permission: {
      role: ["super_admin", "organization"]
    }
  },
];
export default routes;
