import React, { useState } from 'react'
import classes from '../../../assets/styles/employee.module.css'
import ValidateSchema from "../../../helpers/ValidateSchema";
import EmployeeEducationSchema from '../../../utils/SchemasValidation/EmployeeEducationSchema';
import ActionButtons from './ActionButtons'
import showToast from '../../../helpers/showToast'
import { TiDocumentAdd } from 'react-icons/ti'
import DataTable from 'react-data-table-component'
import customStyles from '../../../assets/styles/Table Styles/tableStyle'
import { FaTrashAlt } from "react-icons/fa"
import { BsPencilSquare } from "react-icons/bs"
import { deleteRecord } from '../../../server';
import { EMPLOYEE } from '../../../utils/constants/apiUrl';

const emptyEducation = {
    institute_name: "",
    degree_title: "",
    degree_type: "",
    session: "",
    session_from: "",
    session_to: "",
    documents: []
};

const EducationData = (props) => {
    const [EmployeeEduErrors, setEmployeeEduErrors] = useState({});
    const [educationFiles, setEducationFiles] = useState([])
    const validate = async (e) => {
        props.setAddEducation(false)
        props.lastStep()
    }

    async function deleteEdu(id) {
        let { status, message } = await deleteRecord(
            EMPLOYEE + "/experience/delete/" + id,
            Object.keys(props.loggedUser).length !== 0 ? props.loggedUser?.token : null
        )
        if (status)
            showToast(status, message)
    }

    const handleEducationAdd = (e) => {
        e.preventDefault()
        let EeduErrors = ValidateSchema(props.employeeEducation, EmployeeEducationSchema)
        setEmployeeEduErrors(EeduErrors)
        if (!EeduErrors.institute_name && !EeduErrors.degree_title && !EeduErrors.degree_type && !EeduErrors.session_from && !EeduErrors.session_to) {
            props.setEducation([...props.education, props.employeeEducation])
            props.setEmployeeEducation(emptyEducation)
            const newEducationArray = [...props.employee.education, props.employeeEducation];
            props.setEmployee({ ...props.employee, education: newEducationArray })
            props.setAddEducation(false)
            setEducationFiles([])
        }
    };

    const handleEmployeEduChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        props.setEmployeeEducation({ ...props.employeeEducation, [name]: value });
    };

    const handleFileChangeEDU = (e) => {
        const selectedFiles = e.target.files;
        const fileUrls = [...educationFiles];

        for (let i = 0; i < selectedFiles.length; i++) {
            const fileType = selectedFiles[i].type.split('/')[0];
            if (fileType === 'image' || fileType === 'pdf' || fileType === 'application') {
                // const fileUrl = URL.createObjectURL(selectedFiles[i]);
                // fileUrls.push(fileUrl);
                fileUrls.push(selectedFiles[i]);
            }
        }
        props.setEmployeeEducation({ ...props.employeeEducation, documents: fileUrls });
        setEducationFiles(fileUrls)
    };



    // const removeEduDocument = (e) => {
    //     const id = parseInt(e.target.id);
    //     const filteredFiles = educationFiles.filter((data, index) => index !== id);
    //     setEducationFiles(filteredFiles);
    //     props.setEmployeeEducation({ ...props.employeeEducation, documents: filteredFiles });
    // }

    const removeEduDocument = (e, index) => {
        e.stopPropagation()
        const filteredFiles = educationFiles.filter((data, i) => i !== index);
        setEducationFiles(filteredFiles);
        props.setEmployeeEducation({ ...props.employeeEducation, documents: filteredFiles });
    };

    const handleFileChange = (e, index) => {
        const selectedFiles = e.target.files;
        const fileUrls = [...props.education];

        for (let i = 0; i < selectedFiles.length; i++) {
            const fileUrl = URL.createObjectURL(selectedFiles[i]);
            fileUrls[index]["documents"] = [...fileUrls[index]["documents"], fileUrl]
        }
        props.setEducation(fileUrls);
    };

    const removeDocument = (index, documentIndex) => {
        const education = [...props.education];
        const documents = [...education[index].documents];
        documents.splice(documentIndex, 1);
        education[index].documents = documents;
        props.setEducation(education);
    };

    const educationColumn = [
        {
            name: 'Institute Name',
            selector: row => row.institute_name,
            sortable: true
        },
        {
            name: 'Degree Title',
            selector: row => row.degree_title,
            sortable: true,
        },
        {
            name: 'Degree Level',
            selector: row => row.degree_type,
            sortable: true
        },
        {
            name: 'Session',
            selector: row => row.session,
            sortable: true,
            button: true,
        },
        // {
        //     name: 'Documents',
        //     selector: row => row.documents,
        // },
        {
            name: 'Action',
            button: true,
            cell: (row, index) => <div className={classes.actionIcons}>

                <FaTrashAlt
                    className={classes.infoIcon}
                    onClick={() => {
                        let newEdu = [...props.employee.education]
                        newEdu.splice(index, 1)
                        props.setEducation(newEdu)
                        props.setEmployee({ ...props.employee, education: newEdu })
                        deleteEdu(row.id)
                    }}
                />
                <BsPencilSquare
                    className="edit"
                    onClick={() => {
                        const newRow = {
                            ...row,
                            session_from: row.session.substr(0, 4),
                            session_to: row.session.substr(5, 9),
                            index: index
                        }
                        props.setEmployeeEducation(newRow)
                        props.setAddEducation(true)
                    }}
                />
            </div>
        }
    ]

    return <>
        <section className={classes.eduSection}>
            <button className={classes.AddButton}
                onClick={(e) => {
                    e.preventDefault()
                    if (props.AddEducation)
                        handleEducationAdd(e)
                    else
                        props.setAddEducation(true)
                }}
            >
                Add Education
            </button>


            {props.AddEducation === true &&
                <div className={classes.arrayGridview}>
                    <div className={classes.arrayview}>
                        <div className={classes.EmployeeView}>
                            <div>
                                <label className='Starter'>Institute Name*</label>

                                <input
                                    value={props.employeeEducation.institute_name}
                                    className={EmployeeEduErrors?.institute_name ? "form-control textInput errorInput " : "form-control textInput"}
                                    onChange={handleEmployeEduChange}
                                    type="text"
                                    name="institute_name"
                                    placeholder='Institute Name'
                                />
                                <p className="errorText">{EmployeeEduErrors?.institute_name || ""}</p>
                            </div>

                            <div>
                                <label className='Starter'>Degree Title*</label>
                                <input
                                    value={props.employeeEducation.degree_title}
                                    className={EmployeeEduErrors?.degree_title ? "form-control textInput errorInput " : "form-control textInput"}
                                    onChange={handleEmployeEduChange}
                                    type="text"
                                    name="degree_title"
                                    placeholder='Degree Title'
                                />
                                <p className="errorText">{EmployeeEduErrors?.degree_title || ""}</p>
                            </div>

                            <div>
                                <label className='Starter'>Degree Type*</label>
                                <input
                                    value={props.employeeEducation.degree_type}
                                    className={EmployeeEduErrors?.degree_type ? "form-control textInput errorInput " : "form-control textInput"}
                                    onChange={handleEmployeEduChange}
                                    type="text"
                                    name="degree_type"
                                    placeholder='Degree Type'
                                />
                                <p className="errorText">{EmployeeEduErrors?.degree_type || ""}</p>
                            </div>

                        </div>

                        <h5 >Session</h5>
                        <div className={classes.EmployeeView}>

                            <div>
                                <label className='Starter'>From*</label>
                                <input
                                    value={props.employeeEducation.session_from}
                                    className={EmployeeEduErrors?.session_from ? "form-control textInput errorInput " : "form-control textInput"}
                                    onChange={handleEmployeEduChange}
                                    type="number"
                                    placeholder='Year'
                                    name="session_from"
                                />
                                <p className="errorText">{EmployeeEduErrors?.session_from || ""}</p>
                            </div>
                            <div>
                                <label className='Starter'>To*</label>
                                <input
                                    value={props.employeeEducation.session_to}
                                    className={EmployeeEduErrors?.session_to ? "form-control textInput errorInput " : "form-control textInput"}
                                    onChange={handleEmployeEduChange}
                                    type="number"
                                    placeholder='Year'
                                    name="session_to"
                                />
                                <p className="errorText">{EmployeeEduErrors?.session_to || ""}</p>
                            </div>
                        </div>
                        <h5 >Documents</h5>
                        <div className={classes.educationDocuments}>
                            <label className={classes.documentFlex}>
                                <input type='file'
                                    className={classes.noDisplay}
                                    onChange={handleFileChangeEDU}
                                    accept='image/*,.pdf'
                                    multiple={true}
                                />
                                {props.employeeEducation.documents?.length > 0 &&
                                    props.employeeEducation.documents?.map((data, index) => {
                                        if (data.type === "application/pdf") {
                                            return (
                                                <label className={classes.documentImage} key={index}>
                                                    <iframe src={URL.createObjectURL(data)} title="File preview" className={classes.documentImageSize} />
                                                    <FaTrashAlt className={classes.documentDeleteIcon} onClick={(e) => removeEduDocument(e, index)} />
                                                </label>
                                            );
                                        } else {
                                            return (
                                                <label className={classes.documentImage} key={index}>
                                                    <img src={URL.createObjectURL(data)} alt="" className={classes.documentImageSize} />
                                                    <FaTrashAlt className={classes.documentDeleteIcon} onClick={(e) => removeEduDocument(e, index)} />
                                                </label>
                                            );
                                        }
                                    })
                                }
                                <TiDocumentAdd className={classes.documentIcon} />
                            </label>

                        </div>
                    </div>

                    <button
                        className={classes.InitialAddButton}
                        onClick={(e) => {
                            const newEdu = [...props.education];
                            const index = props.employeeEducation.index
                            if (index >= 0) {
                                newEdu[index] = props.employeeEducation
                                props.setEducation(newEdu);
                                props.setEmployee({ ...props.employee, education: newEdu })
                                props.setEmployeeEducation(emptyEducation)
                                showToast("ok", "Education Updated")
                            }
                            else
                                handleEducationAdd(e)
                            props.setAddEducation(false)
                            setEmployeeEduErrors({})
                        }}
                    >
                        Save
                    </button>
                    {/* <button
                        className={classes.InitialAddButton}
                        onClick={() => {
                            props.setAddEducation(false)
                            setEmployeeEduErrors({})
                        }}
                    >
                        Undo
                    </button> */}
                </div>
            }

            <div className={classes.tableMargin}>
                <DataTable
                    columns={educationColumn}
                    data={props.employee.education}
                    highlightOnHover
                    responsive
                    striped
                    customStyles={customStyles}
                />
            </div>

            <br />
            <ActionButtons {...props} nextStep={validate} reset={props.setAddEducation} />
        </section>
    </>
}

export default EducationData