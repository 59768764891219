import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { loggedInUser } from '../../utils/store';
import { AiOutlinePlus } from 'react-icons/ai'
import { useEffect, useState } from 'react';
import Permission from '../../utils/functions/Permission'
import { Button } from 'reactstrap';

const CreationButton = (props) => {
    const [loggedUser] = useAtom(loggedInUser)
    const [permission, setPermission] = useState()
    const navigate = useNavigate()
    useEffect(() => {
        if (loggedUser?.user?.userType === "employee")
            Permission(loggedUser)
                .then((result) => {
                    setPermission(result.Permissions.find(data => data.name === (props.module?.toLowerCase() + "-create")))
                })
    }, [loggedUser])
    return (
        (loggedUser?.user?.userType !== "employee" || permission) &&
        <Button color='primary' className="create" onClick={() => navigate(props.link)}>
            <AiOutlinePlus className='buttonIcons' />  Create
        </Button>
    )
}

export default CreationButton