import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { BsPencilSquare } from 'react-icons/bs'
import Select from 'react-select'
import { ROLE } from '../../utils/constants/apiUrl'
import { get } from "../../server";
import { loggedInUser } from "../../utils/store";
import { useAtom } from "jotai";
import classes from '../../assets/styles/permissions.module.css'
import { loadOrganization } from '../../helpers/apis'
import CreationButton from '../../components/Global Components/CreationButton';
import TableFilter from '../../components/Global Components/TableFilter';
import TableComponent from '../../components/Global Components/DataTable';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { BiRefresh } from 'react-icons/bi';

const Organization = () => {
    const [loggedUser] = useAtom(loggedInUser);

    const [query, setQuery] = useState("")
    const [organization, setOrganization] = useState()
    const [organizationOptions, setOrganizationOptions] = useState([])

    const [Permissions, setPermissions] = useState()
    const [permissions, setPermission] = useState()

    const columns = [
        {
            name: 'Permission Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: "Action",
            selector: row => row.action,
            button: true,
            cell: (row) => <div
                className={classes.actionIcons}
            >
                <Link
                    to={`/roles-permissions/AddEditRoles-permissions/${row.id}`}
                    className={classes.edit}
                >
                    <BsPencilSquare />
                </Link>
            </div>
        },

    ]

    const [filteredData, setFilteredData] = useState()

    useEffect(() => {
        setFilteredData(Permissions?.filter(dat => dat.name.toLowerCase().includes(query)))
        if (query === "")
            setFilteredData()
    }, [query])

    async function loadPermissions() {
        let { status, data } = await get(
            ROLE + "/listing",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status === 200)
            setPermission(data)
    }

    useEffect(() => {
        loadOrganization(loggedUser, setOrganizationOptions)
        loadPermissions()
    }, [])

    useEffect(() => {
        if (loggedUser?.user.userType === "organization") {
            setPermissions(permissions)
        }
    }, [loggedUser, permissions])

    const handleSelectChange = (selectedOptions) => {
        const filteredPermissions = permissions?.filter(data => data.organizationId === selectedOptions.value)
        setPermissions(filteredPermissions)
        setOrganization(selectedOptions)
        if (query)
            setFilteredData(filteredPermissions.filter(dat => dat.name.toLowerCase().includes(query)))
    };

    return <>
        <Row>
            <Col sm="12">
                <Card>
                    <CardHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">Roles & Permissions</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><Link to="/home">Dashboard</Link></li>
                                        <li className="breadcrumb-item active">Roles & Permissions</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </CardHeader>

                    <CardBody>

                    </CardBody>
                </Card>
            </Col>
            <Col sm="12">
                <Card>
                    <CardBody>


                        <CreationButton link={"/roles-permissions/AddEditRoles-permissions"} />
                        <Button color="success" className='create' onClick={() => loadPermissions()}>
                            <BiRefresh className='buttonIcons' />
                            Refresh
                        </Button>
                        <TableFilter
                            query={query}
                            setQuery={setQuery}
                        />
                        {loggedUser?.user?.userType === "super_admin" && <div className={classes.selectOrg}>
                            <Select
                                options={organizationOptions}
                                value={organization}
                                onChange={handleSelectChange}
                                placeholder="Select Organization"
                                className={classes.setWidth}
                            />
                        </div>}

                        <TableComponent
                            columns={columns}
                            Data={Permissions}
                            filteredData={filteredData}
                            message={"Please Select Organization to view data."}
                            allcheck={false}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
}

export default Organization