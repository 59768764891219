import { useParams } from "react-router-dom";
import { Row, Col, Button } from "reactstrap";

const ActionButtons = (props) => {
    const { employeeID } = useParams()
    const handleBack = () => {
        // props.reset(false)
        props.previousStep();
    };

    const handleNext = () => {
        props.nextStep();
    };

    const handleFinish = () => {
        props.lastStep();
    };

    return (
        <div>
            <Row>
                {props.currentStep > 1 && (
                    <Col>
                        <Button
                            color="primary"
                            onClick={handleBack}
                            className="backButton"
                        >Back</Button>
                    </Col>
                )}
                <Col>
                    {props.currentStep < props.totalSteps && (
                        <Button
                            color="primary"
                            onClick={handleNext}
                            className="nextButton"
                        >Next</Button>
                    )}
                    {props.currentStep === props.totalSteps && (
                        <Button
                            color="primary"
                            onClick={handleFinish}
                            className="nextButton"
                        >{employeeID ? "Update" : "Create"}</Button>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default ActionButtons