import React, { useEffect, useState, useRef } from 'react'
import { Delete } from '../../components/Modal/Delete'
import { BulkDelete } from '../../components/Modal/BulkDelete'
import classes from '../../assets/styles/project.module.css'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";
import { PROJECT } from '../../utils/constants/apiUrl'
import { get } from "../../server";
import Select from 'react-select'
import { loadOrganization } from '../../helpers/apis'
import CreationButton from '../../components/Global Components/CreationButton';
import BulkDeleteButton from '../../components/Global Components/BulkDelete';
import TableFilter from '../../components/Global Components/TableFilter';
import TableComponent from '../../components/Global Components/DataTable';
import DeleteAction from '../../components/Global Components/DeleteAction';
import EditAction from '../../components/Global Components/EditAction';
import { Link, useLocation } from 'react-router-dom';
import showToast from '../../helpers/showToast';
import { Button } from 'reactstrap';
import { BiRefresh } from 'react-icons/bi';

const Users = () => {
    const [loggedUser] = useAtom(loggedInUser)
    const location = useLocation()
    const org = new URLSearchParams(location.search).get('org');
    const [role, setRole] = useState()

    useEffect(() => {
        setRole(loggedUser?.user?.userType)
    }, [loggedUser])

    const [data, setData] = useState()
    const [Data, setDATA] = useState()
    const [query, setQuery] = useState("")
    const [showModal, setShowModal] = useState("close")
    const [select, setSelect] = useState(false)
    const projectData = useRef()

    const [organization, setOrganization] = useState()
    const [organizationOptions, setOrganizationOptions] = useState([])

    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        if (org) {
            setOrganization(organizationOptions.find(data => data.value == org))
            setDATA(data?.filter(data => data.organizationId == org))
        }
    }, [org, organizationOptions, data])

    async function loadProject(page, size = perPage) {
        let { status, message, data } = await get(
            PROJECT + '/listing?page=' + page + "&perPage=" + size,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            setData(data.data)
            setDATA(data.data)
            setTotalRows(data.totalItems)
        } else if (status === 401)
            showToast(status, message)
    }

    useEffect(() => {
        loadOrganization(loggedUser, setOrganizationOptions)
        loadProject()
    }, [currentPage, perPage])

    const handleCheck = ({ selectedRows }) => {
        if (selectedRows.length > 0)
            setSelect(true)
        else if (selectedRows.length === 0)
            setSelect(false)
        projectData.current = selectedRows
    }

    const columns = [

        {
            name: 'Project Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Organization',
            selector: row => row.Organization.name,
            sortable: true,
            omit: role === "super_admin" ? false : true
        },
        {
            name: 'Departments',
            selector: row => row.Departments,
            sortable: true,
            // omit: role === "super_admin" ? true : false
            cell: (row) => <div>
                {row.Departments?.map(dept => dept.name).join(', ')}
            </div>
        },
        {
            name: 'Project Lead',
            selector: row => row.lead_name,
            sortable: true
        },

        {
            name: "Action",
            selector: row => row.action,
            // button: true,
            cell: (row) => <div className={classes.actionIcons}>
                <DeleteAction
                    Data={projectData}
                    row={row}
                    setShowModal={setShowModal}
                    module="Projects"
                />
                <EditAction
                    link={"/project/AddEditProject"}
                    id={row.id}
                    module="Projects"
                />
            </div>

        },

    ]
    const [filteredData, setFilteredData] = useState()

    useEffect(() => {
        setFilteredData(data?.filter(dat =>
            dat.name.toLowerCase().includes(query)
        ))
    }, [query])

    const handleSelectChange = (selectedOptions) => {
        setOrganization(selectedOptions)
        if (selectedOptions === null) {
            setDATA(data)
            if (query)
                setFilteredData(data.filter(dat =>
                    dat.name.toLowerCase().includes(query)))

        }
        else {
            const filtered = data.filter(data => data.organizationId === selectedOptions?.value)
            if (query)
                setFilteredData(filtered.filter(dat =>
                    dat.name.toLowerCase().includes(query)))
            setDATA(filtered)
        }
    };

    useEffect(() => { loadProject() }, [showModal])

    return <>
        <div className='card'>
            <div className="card-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <h2 className="card-title">Project View</h2>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Project</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <section className="card-body">
                <CreationButton link={"/project/AddEditProject"} module="Projects" />
                {select && <span>
                    <BulkDeleteButton
                        setShowModal={setShowModal}
                        module="Projects"
                    />
                    {/* <ActiveButtons
                    activate={activate}
                    Deactivate={Deactivate}
                /> */}
                </span>}
                <Button color="success" className='create' onClick={() => { setPerPage(10); setCurrentPage(1) }}>
                    <BiRefresh className='buttonIcons' />
                    Refresh
                </Button>

                <TableFilter
                    query={query}
                    setQuery={setQuery}
                />

                {role === "super_admin" && <Select
                    options={organizationOptions}
                    value={organization}
                    isClearable={true}
                    onChange={handleSelectChange}
                    placeholder="Select Organization"
                    className="inlineDropdown"
                />}

                <TableComponent
                    columns={columns}
                    Data={Data}
                    filteredData={filteredData}
                    handleCheck={handleCheck}
                    query={query}
                    paginationServer={true}
                    totalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                />

                <Delete showModal={showModal === "delete"}
                    setShowModal={setShowModal}
                    DATA={projectData.current}
                    type={"Project"}
                    module={PROJECT}
                />
                <BulkDelete showModal={showModal === "Bulk-delete"}
                    setShowModal={setShowModal}
                    DATA={projectData.current}
                    module={PROJECT}
                />
            </section>
        </div>
    </>
}

export default Users