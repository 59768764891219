import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import classes from '../assets/styles/login.module.css'

function App() {
    const token = sessionStorage.getItem("token")
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [type, setType] = useState('password')
    const [icon, setIcon] = useState("nav-icon fas fa-eye")

    useEffect(() => {
        if (token === null) {
            window.location.href = '/login'
        }
    }, [token])

    const handleToggle = () => {
        if (type === 'password') {
            setIcon("nav-icon fas fa-eye-slash")
            setType('text')

        }
        else {
            setIcon("nav-icon fas fa-eye")
            setType('password')
        }
    }

    async function resetPassword(event) {
        event.preventDefault()
        if (password !== confirmPassword) {
            swal({
                title: "",
                text: "Password do not match",
                icon: "warning",
                timer: 2000,
                showCancelButton: false,
                showConfirmButton: false,
                button: "Continue",
            })
        }
    }

    return < >
        <form
            onSubmit={resetPassword}
            className='Logincontainer' >
            <h2 className='Header'>Reset Password</h2>
            <b>Password</b>
            <input
                value={password} className="login"
                onChange={(e) => setPassword(e.target.value)}
                type={type} placeholder="Password"
            /><b> Confirm Password</b>
            <input
                value={confirmPassword} className="login"
                onChange={(e) => setConfirmPassword(e.target.value)}
                type={type} placeholder="Confirm Password"
            />
            <span className='eye' onClick={handleToggle} style={{ bottom: '215px' }}><i className={icon} /></span>
            <span className='eye' onClick={handleToggle}><i className={icon} /></span><br />
            <input className='button'
                type="submit" value="Reset Password" /><br />
            <Link to='/register' className='links'>Login</Link>
        </form>
    </ >
}

export default App;
