import React, { useEffect, useState, useRef } from 'react'
import classes from '../../assets/styles/clockIn.module.css'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";
import TableComponent from '../../components/Global Components/DataTable'
import { Button, Col, Row, Card, CardBody, CardHeader } from 'reactstrap'
import { Link } from 'react-router-dom'
import { get } from '../../server';
import { ATTENDANCE } from '../../utils/constants/apiUrl';
import DatePicker from "react-multi-date-picker";
import { BiRefresh } from 'react-icons/bi';
import { Overtime } from '../../components/Modal/Overtime'


const App = () => {
    const [loggedUser] = useAtom(loggedInUser);
    const [data, setData] = useState()
    const [Data, setDATA] = useState()
    const [date, setDate] = useState()
    const rowData = useRef()
    const [modal, setModal] = useState()
    const [overtimes, setOvertimes] = useState()

    async function loadClocks() {
        let { status, data } = await get(
            ATTENDANCE + "/clockInOut/id",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status === 200) {
            const report = generateWeeklyReport(data.ClockInOuts)
            setData(report)
            setDATA(report)
            setOvertimes(data.Overtimes)
        }
    }

    useEffect(() => {
        loadClocks()
    }, [])

    function generateWeeklyReport(data) {
        // Find the oldest and newest dates
        const dates = data.map((record) => new Date(record.date));
        const oldestDate = new Date(Math.min(...dates));
        const newestDate = new Date(Math.max(...dates));

        // Find the week start date based on the oldest date
        const weekStartDate = new Date(oldestDate);
        weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay());

        // Generate an array of week objects
        const weeks = [];
        let currentDate = new Date(weekStartDate);

        while (currentDate <= newestDate) {
            const weekEndDate = new Date(currentDate);
            weekEndDate.setDate(weekEndDate.getDate() + 6);

            const weekNumber = getWeekNumber(currentDate);
            const month = currentDate.toLocaleString("default", { month: "long" });
            const year = currentDate.getFullYear();

            const weekData = data.filter((record) => {
                const recordDate = new Date(record.date);
                return recordDate >= currentDate && recordDate <= weekEndDate;
            });

            const totalHours = weekData.reduce(
                (total, record) => total + (record.hours || 0),
                0
            );

            weeks.push({
                WeekNumber: weekNumber,
                Month: month,
                Year: year,
                TotalHours: totalHours,
            });

            currentDate.setDate(currentDate.getDate() + 7);
        }

        return weeks;
    }

    function getWeekNumber(date) {
        const target = new Date(date.valueOf());
        const dayNumber = (date.getDay() + 6) % 7;
        target.setDate(target.getDate() - dayNumber + 3);
        const firstThursday = new Date(target.getFullYear(), 0, 1);

        if (firstThursday.getDay() !== 4) {
            firstThursday.setMonth(0, 1 + ((4 - firstThursday.getDay() + 7) % 7));
        }

        return 1 + Math.ceil((target - firstThursday) / (7 * 24 * 60 * 60 * 1000));
    }

    function weekSelect(week) {
        setDATA(data.filter(data => data.WeekNumber == week[0].format("WW")))
    }

    const columns = [
        {
            name: "Week No.",
            selector: row => row.WeekNumber,
            sortable: true,
            center: true,
        },
        {
            name: 'Month',
            selector: row => row?.Month,
            sortable: true,
        },
        {
            name: 'Year',
            selector: row => row?.Year,
            sortable: true,
            center: true
        },
        {
            name: "Total Hours",
            selector: row => row?.TotalHours,
            sortable: true,
            center: true
        },
        {
            name: "Action",
            sortable: true,
            center: true,
            cell: (row) => {
                const currentWeekIndex = data.findIndex((item) => item.WeekNumber === row.WeekNumber);
                const nextWeekExists = currentWeekIndex < data.length - 1;
                let overtime = overtimes.find(data => data.weekNumber === row.WeekNumber)
                if (nextWeekExists) {
                    return (
                        !overtime ? <Button
                            className={`btn-simple ${classes.disputeButton}`}
                            color="google"
                            onClick={() => handleAction(row)}
                        >
                            Request Overtime
                        </Button>
                            : <Button className={`btn-simple ${classes.disputeButton}`} disabled="true"
                                color={overtime.status === "pending" ? "info" : overtime.status === "accepted" ? "success" : overtime.status === "rejected" ? "danger" : ""}>{overtime.status}</Button>
                    );
                } else {
                    return null;
                }
            },
        }
    ]

    function handleAction(row) {
        rowData.current = row
        toggleModal()
    }

    const toggleModal = () => {
        setModal(!modal);
    };

    useEffect(() => {
        if (!modal)
            loadClocks()
    }, [modal])
    return <>
        <Row>
            <Col sm="12">
                <Card>
                    <CardHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">Clock In & Out Report</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" ><Link to="/home">Dashboard</Link></li>
                                        <li className="breadcrumb-item active">Clock In & Out Panel</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </CardHeader>

                    <CardBody>
                        <Button color="success" className='create' onClick={() => { loadClocks(); setDate(null) }}>
                            <BiRefresh className='buttonIcons' />
                            Refresh
                        </Button>
                        <DatePicker
                            value={date}
                            placeholder="Select Week"
                            inputClass={`form-control ${classes.filter}`}
                            onChange={(e) => {
                                setDate(e)
                                if (e.length > 1)
                                    weekSelect(e)
                            }}
                            calendarPosition="bottom-right"
                            format="DD MMMM YYYY"
                            weekPicker
                            displayWeekNumbers
                        />
                        <TableComponent
                            columns={columns}
                            Data={Data}
                            allcheck={false}
                        />
                        <Overtime
                            modal={modal}
                            toggleModal={toggleModal}
                            loggedUser={loggedUser}
                            data={rowData.current}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
}
export default App