const Joi = require("joi");
const AddEditJobOpeningSchema = Joi.object({
    id: Joi.allow(null),

    name: Joi.string().min(3).max(25).required().messages({
        "string.empty": `Please enter Opening Name`,
        "string.min": `Opening Name must be minimum 3 characters!`,
        "string.max": `Opening Name must be maximum 25 characters!`,
    }),

    employment: Joi.string().required().messages({
        "string.empty": `Please select Employment Type`,
    }),

    work_from: Joi.string().required().messages({
        "string.empty": `Please select Work Type`,
    }),

    vacancies: Joi.number().min(1).required().messages({
        "number.empty": `Please enter Number of Vacancies`,
        "any.required": `Please enter Number of Vacancies`,
        "number.min": `Vacancies must be at least 1!`,
    })

});

export default AddEditJobOpeningSchema;
