import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from "react-toastify"

import "./assets/scss/black-dashboard-pro-react.scss";
import "./assets/demo/demo.css";

import Home from "./pages/Home";
import Settings from './pages/Settings'
import LeaveSettings from './pages/LeaveSettingCriteria'

import Login from './pages/Login'
import ResetPassword from './pages/ResetPassword'

import Organization from './pages/organization/Organization'
import AddEditOrganization from './pages/organization/AddOrganization'
import OrganizationDetail from './pages/organization/OrganizationDetails'

import RolesAndPermissions from './pages/RolesAndPermissions/RolesAndPermissions'
import AddEditRolesAndPermissions from './pages/RolesAndPermissions/AddEditRolesAndPermissions'

import Positions from './pages/positions/position'
import AddEditPositions from './pages/positions/AddEditPosition'

import Department from './pages/Department/Department'
import AddEditDepartment from './pages/Department/AddDepartment'

import Project from './pages/Project/project'
import AddProject from './pages/Project/AddProject'

import Employee from './pages/Employee/Employee'
import EmployeeDetails from './pages/Employee/EmployeeDetails'
import AddEmployee from './pages/Employee/AddEmployee'

import EmployeeAttendance from './pages/Attendance/attendance'
import ClockIn from './pages/ClockInAndOut/ClockInAndOut'
import ClockInPanel from './pages/ClockInAndOut/disputePanel'
import ClockInReport from './pages/ClockInAndOut/Report'
import WeeklyReport from './pages/ClockInAndOut/WeeklyReport'

import EmployeeLeaves from './pages/EmployeeLeaves/Leaves'
import MyLeaves from './pages/EmployeeLeaves/MyLeaves';
import EmployeeApplyLeave from './pages/EmployeeLeaves/leaveForm'
import EmployeeLeaveDetails from './pages/EmployeeLeaves/LeaveDetails'

import LateCriteria from './pages/LateComingCriteria/CriteriaList'

import JobOpenings from './pages/Job Opening/JobOpenings'
import AddEditJobOpening from './pages/Job Opening/AddEditJobOpening'
import JobOpeningDetails from './pages/Job Opening/OpeningInfo'

import Candidate from './pages/candidate/Candidate'
import CandidateDetails from './pages/candidate/CandidateDetails'
import AddCandidate from './pages/candidate/AddCandidate'

import './assets/styles/global.css'
import Authenticator from './utils/functions/Authenticator';
import Layout from './layout/Layout';
import DashboardLayout from './layout/DashboardLayout';
import RequireAuth from './helpers/RequireAuth';

import { useAtom } from "jotai";
import { loggedInUser } from "./utils/store";
import Unauthorized from './helpers/Unauthorized';

function App() {
  const [loggedUser] = useAtom(loggedInUser)

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* Public routes */}
            <Route path="login" element={<Login />} />
            <Route path="" element={<Login />} />
            <Route path="reset-password" element={<ResetPassword />} />

            {/* authorized routes */}
            <Route element={<DashboardLayout
              name={loggedUser?.user?.firstName + " " + loggedUser?.user?.lastName}
              role={loggedUser?.user?.userType === "super_admin" ? "Super Admin" : null}
            />} >
              <Route element={<Authenticator token={loggedUser?.token} />} >

                <Route index element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="settings" element={<Settings />} />
                <Route path="settings/leave-settings" element={<LeaveSettings />} />

                <Route element={<RequireAuth loggedUser={loggedUser} />} >
                  <Route path="/organization" element={<Organization />} />
                  <Route path="/organization/AddEditOrganization" element={<AddEditOrganization />} />
                  <Route path="/organization/AddEditOrganization/:organizationID" element={<AddEditOrganization />} />
                  <Route path="/organization/detail/:organizationID" element={<OrganizationDetail />} />

                  <Route path="/roles-permissions" element={<RolesAndPermissions />} />
                  <Route path="/roles-permissions/AddEditRoles-permissions" exact element={<AddEditRolesAndPermissions />} />
                  <Route path="/roles-permissions/AddEditRoles-permissions/:permissionID" element={<AddEditRolesAndPermissions />} />

                  <Route path="/positions" element={<Positions />} />
                  <Route path="/positions/AddEditPositions" element={<AddEditPositions />} />
                  <Route path="/positions/AddEditPositions/:positionID" element={<AddEditPositions />} />

                  <Route path="/department" element={<Department />} />
                  <Route path="/department/AddEditDepartment" element={<AddEditDepartment />} />
                  <Route path="/department/AddEditDepartment/:departmentID" element={<AddEditDepartment />} />

                  <Route path="/project" element={<Project />} />
                  <Route path="/project/AddEditProject" element={<AddProject />} />
                  <Route path="/project/AddEditProject/:projectID" element={<AddProject />} />

                  <Route path="/employee" element={<Employee />} />
                  <Route path="/employee/info/:employeeID" element={<EmployeeDetails />} />
                  <Route path="/employee/AddEditEmployee/" element={<AddEmployee />} />
                  <Route path="/employee/AddEditEmployee/:employeeID" element={<AddEmployee />} />

                  <Route path="/employee/attendance" element={<EmployeeAttendance />} />
                  <Route path="/leaves" exact element={<EmployeeLeaves />} />
                  <Route path="/leaves/my-leaves" exact element={<MyLeaves />} />
                  <Route path="/leaves/apply" element={<EmployeeApplyLeave />} />
                  <Route path="/leaves/detail/:leaveID" element={<EmployeeLeaveDetails />} />
                  <Route path="/checkin-and-checkout" element={<ClockIn />} />
                  <Route path="/checkin-and-checkout/panel" element={<ClockInPanel />} />
                  <Route path="/checkin-and-checkout/report" element={<ClockInReport />} />
                  <Route path="/checkin-and-checkout/my-report" element={<WeeklyReport />} />

                  <Route path="/openings" element={<JobOpenings />} />
                  <Route path="/openings/AddEditJobOpenings" exact element={<AddEditJobOpening />} />
                  <Route path="/openings/AddEditJobOpenings/:openingID" element={<AddEditJobOpening />} />
                  <Route path="/openings/details/:openingID" element={<JobOpeningDetails />} />

                  <Route path="/candidate" element={<Candidate />} />
                  <Route path="/candidate/info/:candidateID" element={<CandidateDetails />} />
                  <Route path="/candidate/AddEditCandidate" exact element={<AddCandidate />} />
                  <Route path="/candidate/AddEditCandidate/:candidateID" element={<AddCandidate />} />

                  <Route path="/late-coming-criteria" element={<LateCriteria />} />
                </Route>
                <Route path="/unauthorized" element={<Unauthorized />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  )
}

export default App;
