import React, { useState } from 'react'
import classes from '../../../assets/styles/employee.module.css'
import ValidateSchema from "../../../helpers/ValidateSchema";
import AddEditUserSchema from '../../../utils/SchemasValidation/AddEditUserSchema';
import defaultPicture from '../../../assets/images/defaultProfile.png'
import { BiImageAdd } from 'react-icons/bi'
import { FaTrashAlt, FaEye, FaEyeSlash } from 'react-icons/fa';
import ActionButtons from './ActionButtons'

const UserProfile = (props) => {

    const [Usererrors, setUserErrors] = useState({});

    const [src, selectFile] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [image, setImage] = useState(null)

    const handleFileChange = e => {
        setFileName(e.target.files[0].name);
        selectFile(URL.createObjectURL(e.target.files[0]))
        setImage(e.target.files[0])
    }

    const validate = () => {
        let userErrors = ValidateSchema(props.employee, AddEditUserSchema)
        setUserErrors(userErrors);
        if (Object.keys(userErrors).length) return false;
        else {
            props.nextStep();
        }
        return true;
    };

    const [type, setType] = useState('password')

    const handleToggle = () => {
        if (type === 'password') {
            setType('text')

        }
        else {
            setType('password')
        }
    }

    const handleUserChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        props.setEmployee({ ...props.employee, [name]: value });
    };

    return <>

        <div className={classes.centeredItem}>
            <label className={classes.relativeItem}>
                <img
                    src={src ?
                        src : props.employee.avatar === 'Default' ?
                            defaultPicture : props.employee.avatar
                    }
                    alt=""
                    className={classes.avatar}
                />
                <label className={classes.icons}>
                    <label className={classes.ImageDiv2}>
                        <input type='file'
                            className={classes.noDisplay}
                            onChange={handleFileChange}
                            accept='image/*'
                        />
                        <BiImageAdd
                            className={classes.imageIcon}
                        />
                        {(props.employee.avatar !== "Default" || src) ?
                            <FaTrashAlt
                                onClick={(e) => {
                                    e.preventDefault()
                                    selectFile(null)
                                    setFileName(null)
                                    setImage(null)
                                }}
                                className={classes.deleteIcon}
                            /> : null}
                    </label>
                </label>
            </label>
        </div>

        <div className={classes.EmployeeView}>
            <div>

                <label className='Starter'>First Name*</label>
                <input
                    value={props.employee.first_name}
                    className={Usererrors?.first_name ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={handleUserChange}
                    type="text"
                    placeholder="First Name"
                    name='first_name'
                />
                <p className="errorText">{Usererrors?.first_name || ""}</p>

            </div>

            <div>
                <label className='Starter'>Last Name*</label>
                <input
                    value={props.employee.last_name}
                    className={Usererrors?.last_name ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={handleUserChange}
                    type="text"
                    placeholder="Last Name"
                    name='last_name'
                />
                <p className="errorText">{Usererrors?.last_name || ""}</p>
            </div>

            <div>
                <label className='Starter'>Number*</label>
                <input
                    value={props.employee.phone_no}
                    className={Usererrors?.phone_no ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={handleUserChange}
                    type="tel"
                    placeholder="Phone Number"
                    name='phone_no'
                />
                <p className="errorText">{Usererrors?.phone_no || ""}</p>
            </div>

            <div>
                <label className='Starter'>Email</label>
                <input
                    value={props.employee.email}
                    className={Usererrors?.email ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={handleUserChange}
                    type="email"
                    placeholder="Email Address"
                    name='email'
                />
                <p className="errorText">{Usererrors?.email || ""}</p>
            </div>

            <div>
                <label className='Starter'>Username*</label>
                <input
                    value={props.employee.username}
                    className={Usererrors?.username ?
                        "form-control textInput errorInput " :
                        "form-control textInput"}
                    onChange={handleUserChange}
                    type="text"
                    placeholder="Username"
                    name='username'
                    autoComplete='off'
                />
                <p className="errorText">{Usererrors?.username || ""}</p>
            </div>

            <div className={classes.passwordLabel}>
                <label className='Starter'>Password*</label>
                <input
                    value={props.employee.password}
                    className={Usererrors?.password ? "form-control textInput errorInput " : "form-control textInput"}
                    onChange={handleUserChange}
                    type={type}
                    placeholder="Password"
                    name='password'
                    autoComplete='off'
                />
                <label className={classes.eye}
                    onClick={handleToggle}>
                    {type === "password" ? <FaEye /> : <FaEyeSlash />}
                </label>
                <p className="errorText">{Usererrors?.password || ""}</p>
            </div>
        </div>
        {/* <hr className={classes.hrClass} /> */}
        <br />
        <ActionButtons {...props} nextStep={validate} />
    </>
}

export default UserProfile