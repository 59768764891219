const Joi = require("joi");
const ClockInOutDisputeSchema = Joi.object({
    id: Joi.allow(null),
    dispute_type: Joi.string().required().messages({
        "string.empty": `Please select type of dispute`,
        "any.required": `Please select type of dispute`
    }),
    time: Joi.string().required().messages({
        "string.empty": `Please select your corrected time`,
        "any.required": `Please select your corrected time`
    }),
    details: Joi.string().required().messages({
        "string.empty": `Please enter dispute reason`
    }),

});

export default ClockInOutDisputeSchema;
