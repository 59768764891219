import React, { useState } from 'react'
import classes from '../../../assets/styles/employee.module.css'
import ActionButtons from './ActionButtons'
import { TiDocumentAdd } from 'react-icons/ti'
import { FaTrashAlt } from 'react-icons/fa'

const DocumentData = props => {

    const [CV, setCV] = useState(null)
    // const [educationFiles, setEducationFiles] = useState([])
    const [certifications, setCertifications] = useState([])

    const handleFileChangeCV = e => {
        setCV(URL.createObjectURL(e.target.files[0]))
    }

    // const handleFileChangeEDU = (e) => {
    //     const selectedFiles = e.target.files;
    //     const fileUrls = [...educationFiles];

    //     for (let i = 0; i < selectedFiles.length; i++) {
    //         const fileUrl = URL.createObjectURL(selectedFiles[i]);
    //         fileUrls.push(fileUrl);
    //     }
    //     setEducationFiles(fileUrls);
    // };

    // const removeEduDocument = (e) => {
    //     const id = parseInt(e.target.id);
    //     const filteredFiles = educationFiles.filter((data, index) => index !== id);
    //     setEducationFiles(filteredFiles);
    // }

    const handleFileChangeCert = (e) => {
        const selectedFiles = e.target.files;
        const fileUrls = [...certifications];

        for (let i = 0; i < selectedFiles.length; i++) {
            const fileUrl = URL.createObjectURL(selectedFiles[i]);
            fileUrls.push(fileUrl);
        }
        setCertifications(fileUrls);
    };

    const removeCertDocument = (e) => {
        const id = parseInt(e.target.id);
        const filteredFiles = certifications.filter((data, index) => index !== id);
        setCertifications(filteredFiles);
    }

    async function submit() {
        props.lastStep();
        props.completeCallback();
    }

    return <>
        <div className={classes.documentGrid}>
            <label className={classes.documentLabel}>CV</label>
            <label>
                <input type='file'
                    className={classes.noDisplay}
                    onChange={handleFileChangeCV}
                    accept='image/*'
                />

                {CV ? <label className={classes.documentImage}>
                    <img src={CV} alt="" className={classes.documentImageSize} />
                    <FaTrashAlt className={classes.documentDeleteIcon} onClick={() => setCV(null)} />
                </label> :
                    <TiDocumentAdd className={classes.documentIcon} />
                }
            </label>

            {/* <label className={classes.documentLabel}>Educational Documents</label>
            <label>
                <input type='file'
                    className={classes.noDisplay}
                    onChange={handleFileChangeEDU}
                    accept='image/*'
                    multiple={true}
                />
                {educationFiles.length > 0 &&
                    educationFiles.map((data, index) =>
                        <label className={classes.documentImage}>
                            <img src={data} alt="" className={classes.documentImageSize} />
                            <FaTrashAlt className={classes.documentDeleteIcon} key={index + 1} id={index} onClick={removeEduDocument} />
                        </label>)
                }
                <TiDocumentAdd className={classes.documentIcon} />
            </label> */}

            <label className={classes.documentLabel}>Certifications</label>
            <label>
                <input type='file'
                    className={classes.noDisplay}
                    onChange={handleFileChangeCert}
                    accept='image/*'
                    multiple={true}
                />
                {certifications.length > 0 &&
                    certifications.map((data, index) =>
                        <label className={classes.documentImage}>
                            <img src={data} alt="" className={classes.documentImageSize} />
                            <FaTrashAlt className={classes.documentDeleteIcon} key={index + 1} id={index} onClick={removeCertDocument} />
                        </label>)
                }
                <TiDocumentAdd className={classes.documentIcon} />
            </label>
        </div>
        {/* <hr /> */}
        <br />
        <ActionButtons {...props} lastStep={submit} reset={props.setAddEducation} />
    </>

}

export default DocumentData