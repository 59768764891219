import React from "react";
import { BsPencilSquare } from "react-icons/bs"
import { Link } from "react-router-dom"
import { useAtom } from 'jotai';
import { loggedInUser } from '../../utils/store';
import { useEffect, useState } from 'react';
import Permission from '../../utils/functions/Permission'

const EditAction = (props) => {
    const [loggedUser] = useAtom(loggedInUser)
    const [permission, setPermission] = useState()
    useEffect(() => {
        if (loggedUser?.user?.userType === "employee")
            Permission(loggedUser)
                .then((result) => {
                    setPermission(result.Permissions.find(data => data.name === (props.module?.toLowerCase() + "-edit")))
                })
    }, [loggedUser])

    return (
        <>
            {(loggedUser?.user?.userType !== "employee" || permission) &&
                <Link
                    to={`${props.link}/${props.id}`}
                    className="edit"
                >
                    <BsPencilSquare />
                </Link>
            }
        </>
    )
}

export default EditAction