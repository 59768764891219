import React, { useEffect, useState, useRef } from 'react'
import Select from 'react-select'
import { Attendance } from '../../components/Modal/EmployeeAttendance'
import classes from '../../assets/styles/employee.module.css'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";
import { loadOrganization } from '../../helpers/apis'
import DatePicker from "react-multi-date-picker";
import { post } from "../../server";
import { ATTENDANCE } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'
import { loadDepartment } from '../../helpers/apis/index'

import TableComponent from '../../components/Global Components/DataTable';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const Employees = () => {
    const [loggedUser] = useAtom(loggedInUser)

    const [columns, setColumns] = useState()
    const [data, setData] = useState()
    const [Data, setDATA] = useState()
    const [department, setDepartment] = useState([])
    const [departmentOptions, setDepartmentOptions] = useState([])
    const [selectedDepartmentOption, setSelectedDepartmentOption] = useState(null);
    const [showModal, setShowModal] = useState("close")
    const [select, setSelect] = useState(false)
    const attendanceData = useRef()
    const [date, setDate] = useState()
    const [selectedDate, setSelectedDate] = useState()
    const [month, setMonth] = useState()
    const [dateRange, setDateRange] = useState()
    const [filteredData, setFilteredData] = useState()
    const [organization, setOrganization] = useState()
    const [organizationOptions, setOrganizationOptions] = useState([])

    const [filter, setFilter] = useState()

    const filterOptions = [
        { value: 1, label: "Today" },
        { value: 2, label: "Date" },
        { value: 3, label: "Last Week" },
        { value: 4, label: "Month" },
        { value: 5, label: "Range" }
    ]

    useEffect(() => {
        loadDepartment(loggedUser, setDepartmentOptions)
    }, [])

    useEffect(() => {
        if (loggedUser?.user?.userType === "super_admin")
            loadOrganization(loggedUser, setOrganizationOptions)
    }, [loggedUser])

    useEffect(() => {
        if (organization) {
            setFilter({ value: 1, label: "Today" })
            // AttendanceDate(new Date().toISOString().slice(0, 10))
            loadAttendance(new Date().toISOString().slice(0, 10))
            const filtered = departmentOptions.filter(dat => dat.organization_id === organization.value)
            const names = filtered?.map(rows => ({
                value: rows.value,
                label: rows.label
            }))
            setDepartment(names)
        }
        else {
            setFilter()
        }
    }, [organization])

    const handleCheck = ({ selectedRows }) => {
        if (selectedRows.length > 0)
            setSelect(true)
        else if (selectedRows.length === 0)
            setSelect(false)
        attendanceData.current = selectedRows?.map(rows => rows.id)
    }

    const action = (e) => {
        e.preventDefault()
        setShowModal("Attendance")
    }

    const CheckboxSlider = ({ row, index }) => {
        const [isChecked, setIsChecked] = useState(row.is_late ? true : false);

        return (
            <div className="checkboxSlider">
                <input
                    type="checkbox"
                    id={index}
                    checked={isChecked}
                    key={index}
                    onChange={(e) => {
                        setIsChecked(!isChecked);
                        changeStatus(row, index, !isChecked);
                    }}
                />
                <label htmlFor={index}></label>
            </div>
        );
    };

    const changeStatus = (row, index, isLate) => {
        const updatedData = [...data];
        updatedData[index].status = !isLate;
        if (isLate && !row.is_late) {
            updatedData[index].is_late = true;
        } else if (!isLate) {
            delete updatedData[index].is_late;
        }
    };

    async function changeAttendance(e, row) {
        const attendance = e.target.value
        let { status, message } = await post(
            ATTENDANCE + "/updateAttendance",
            { attendance, id: row?.employeeId, date: selectedDate },
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status) {
            showToast(status, message)
            if (status === 200)
                loadAttendance(selectedDate)
        }
    }

    const defaultColumns = [
        {
            name: 'ID',
            selector: row => row?.Employee?.id,
            sortable: true,
            button: true,
        },
        {
            name: 'Name',
            selector: row => row?.Employee?.User?.firstName,
            sortable: true,
            cell: (row) => <div>
                {row?.Employee?.User?.firstName} {row?.Employee?.User?.lastName}
            </div >
        },
        {
            name: 'Department',
            selector: row => row?.Employee?.Department?.name,
            sortable: true
        },
        {
            name: 'Attendance',
            selector: row => row.attendance,
            sortable: true,
            cell: (row) => <select
                name="status"
                className="dropDown"
                value={row.attendance}
                onChange={(e) => changeAttendance(e, row)}
            >
                <option value="present" className="dropdownOptions">Present</option>
                <option value="absent" className="dropdownOptions">Absent</option>
            </select>
        },
        {
            name: 'Late',
            selector: row => row.isLate,
            sortable: true,
            cell: (row, index) => <CheckboxSlider row={row} index={index} />
        },
    ]

    const WeekColumn = [
        {
            name: 'ID',
            selector: row => row?.employee?.id,
            sortable: true,
            button: true
        },
        {
            name: 'Name',
            selector: row => row?.employee?.User.firstName,
            sortable: true,
            cell: (row) => <div>
                {row?.employee?.User.firstName} {row?.employee?.User.lastName}
            </div >
        },
        {
            name: 'Department',
            selector: row => row?.employee?.Department?.name,
            sortable: true
        },
        {
            name: 'Presents',
            selector: row => row.is_present,
            cell: row => row.presentCount

        },
        {
            name: 'Absents',
            selector: row => row.is_present,
            cell: row => row.absentCount
        },
        {
            name: 'Lates',
            selector: row => row.is_present,
            cell: row => row.lateCount
        }
    ]

    const handleOrgSelect = (selectedOptions) => {
        setOrganization(selectedOptions)
        if (selectedOptions === null)
            setDATA(data)
        else {
            const filtered = data?.filter(data => data.organizationId == selectedOptions?.value)
            setDATA(filtered)
        }
    };

    const handleDepartSelect = (selectedOptions) => {
        setSelectedDepartmentOption(selectedOptions)
        if (selectedOptions !== null) {
            const filtered = data.filter(data => data.department_id == selectedOptions?.value)
            setDATA(filtered)
        } else {
            const filtered = data.filter(data => data.organization_id == organization?.value)
            setDATA(filtered)
        }
    };

    useEffect(() => {
        setFilteredData(Data?.filter(dat => dat.department_id == selectedDepartmentOption?.value))
    }, [selectedDepartmentOption])

    const handleFilterChange = (selectedOptions) => {
        setFilter(selectedOptions)
        if (selectedOptions.value === 1) {
            setDate(new Date().toISOString().slice(0, 10))
        }
        else
            setDate()
    }

    useEffect(() => {
        if (filter?.value === 3) {
            const today = new Date();
            const lastWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7).toString().replace(/GMT[+-]\d{4}/, 'GMT+0000');
            const lastWeekEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1).toString().replace(/GMT[+-]\d{4}/, 'GMT+0000');
            allAttendanceDate(new Date(lastWeekStart).toISOString(), new Date(lastWeekEnd).toISOString());
        }
    }, [filter?.value === 3])

    useEffect(() => {
        if (filter?.value === 4 || filter?.value === 4) {
            setColumns(WeekColumn)
        }
        if (filter?.value === 1 || filter?.value === 2)
            setColumns(defaultColumns)
        else
            setColumns(WeekColumn)
    }, [filter])

    const getMonthRange = (month) => {
        const startDate = new Date(`${month.year}-${month.month.number}-1`).toString().replace(/GMT[+-]\d{4}/, 'GMT+0000')
        const endDate = new Date(`${month.year}-${month.month.number}-${month.month.length}`).toString().replace(/GMT[+-]\d{4}/, 'GMT+0000')
        allAttendanceDate(new Date(startDate).toISOString(), new Date(endDate).toISOString())
    }

    useEffect(() => {
        if (dateRange) {
            allAttendanceDate(dateRange[0]?.format("YYYY-MM-DD"), dateRange[1]?.format("YYYY-MM-DD"))
        }
    }, [dateRange])

    async function loadAttendance(date) {
        setSelectedDate(date)

        let { status, data } = await post(
            ATTENDANCE + "/listingByDate",
            { date },
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status === 200) {
            if (!data.length) {
                createDate(date)
            }
            if (organization) {
                const filtered = data.find(dat => dat.organizationId === organization?.value)
                if (selectedDepartmentOption) {
                    const departFilter = filtered.filter(dat => dat.department_id === selectedDepartmentOption.value)
                    setFilteredData(departFilter)
                    setFilteredData(departFilter)
                }
                else {
                    setData(filtered?.Attendance_Records)
                    setDATA(filtered?.Attendance_Records)
                }
            }
        }
    }

    async function createDate(date) {
        let { status, message } = await post(
            ATTENDANCE + "/addDate",
            { date },
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status) {
            showToast(status, message)
            if (status === 200)
                loadAttendance(date)
        }
    }

    async function allAttendanceDate(startDate, endDate) {
        let { status, data } = await post(
            ATTENDANCE + "/listingByRange",
            { startDate, endDate },
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            if (organization) {
                const dataa = attendanceSummary(data)
                const filtered = dataa.filter(dat => dat?.employee.organizationId === organization?.value)
                if (selectedDepartmentOption) {
                    const departFilter = filtered.filter(dat => dat?.employee.departmentId === selectedDepartmentOption.value)
                    setFilteredData(departFilter)
                    setFilteredData(departFilter)
                }
                else {
                    setData(filtered)
                    setDATA(filtered)
                }
            }
        }
    }

    const attendanceSummary = (data) => {
        const reducedData = data.map((record) => {
            const { Attendance_Records } = record;

            const presentCount = Attendance_Records.reduce((count, record) => {
                return record.attendance === 'present' ? count + 1 : count;
            }, 0);

            const absentCount = Attendance_Records.reduce((count, record) => {
                return record.attendance === 'absent' ? count + 1 : count;
            }, 0);

            const lateCount = Attendance_Records.reduce((count, record) => {
                return record.attendance === 'late' ? count + 1 : count;
            }, 0);

            return {
                presentCount,
                absentCount,
                lateCount,
                employee: record
            };
        });

        return reducedData;
    };

    async function bulkChangeStatus(attendance) {
        let { status, message } = await post(
            ATTENDANCE + "/bulkUpdate",
            { attendance: attendance, ids: attendanceData.current },
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status) {
            showToast(status, message)
            if (status === 200)
                loadAttendance(selectedDate)
        }
    }

    return <>
        <div className='card'>
            <div className="card-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <h2 className="card-title">Employee Attendance</h2>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Attendance</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <section className="card-body">
                <span>
                    {select && <span>
                        <Button
                            className={classes.absent}
                            color="danger"
                            onClick={() => bulkChangeStatus("absent")}
                        >
                            Absent
                        </Button>
                        <Button
                            className="activate"
                            onClick={() => bulkChangeStatus("present")}
                            // onClick={activate}
                            color="info"
                        >Present
                        </Button>
                    </span>}
                </span>

                {loggedUser?.user?.userType === "super_admin" && <Select
                    options={organizationOptions}
                    value={organization}
                    isClearable={true}
                    onChange={handleOrgSelect}
                    placeholder="Select Organization"
                    className="inlineDropdown"
                />}

                <Select
                    options={department}
                    value={selectedDepartmentOption}
                    isClearable={true}
                    placeholder="Filter Department"
                    onChange={handleDepartSelect}
                    className="inlineDropdown"
                />

                {organization && <Select
                    options={filterOptions}
                    value={filter}
                    placeholder="Filter Data"
                    onChange={handleFilterChange}
                    className="inlineDropdown"
                />}

                {filter?.value === 2 &&
                    <DatePicker
                        value={date}
                        placeholder="Select Date"
                        inputClass={`form-control ${classes.filter}`}
                        onChange={(e) => loadAttendance(e.format("YYYY-MM-DD"))}
                        calendarPosition="bottom-right"
                        format="DD MMMM YYYY"
                    />}

                {filter?.value === 4 &&
                    <DatePicker
                        value={month}
                        placeholder="Select Month"
                        inputClass={`form-control ${classes.filter}`}
                        onlyMonthPicker={true}
                        onChange={getMonthRange}
                        calendarPosition="bottom-right"
                        format="MMMM YYYY"
                    />}
                {filter?.value === 5 &&
                    <DatePicker
                        value={dateRange}
                        placeholder="Select Range of Dates"
                        inputClass={`form-control ${classes.filter}`}
                        onChange={dateObjects => {
                            setDateRange(dateObjects)
                        }}
                        calendarPosition="bottom-right"
                        format="DD MMMM YYYY"
                        range
                        rangeHover
                    />}

                <TableComponent
                    columns={columns}
                    Data={Data}
                    filteredData={filteredData}
                    handleCheck={handleCheck}
                />

                <Attendance
                    showModal={showModal === "Attendance"}
                    setShowModal={setShowModal}
                    data={attendanceData.current}
                />
            </section>
        </div>
    </>
}

export default Employees