import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import classes from '../../assets/styles/jobOpening.module.css'
import { useParams, useNavigate, Link } from "react-router-dom";
import { get, post } from "../../server";
import ValidateSchema from "../../helpers/ValidateSchema";
import AddEditJobOpeningSchema from '../../utils/SchemasValidation/AddEditJobOpeningSchema'
import { OPENING } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'
import { useAtom } from "jotai";
import { loggedInUser } from "../..//utils/store";
import { loadOrganization, loadDepartment, loadPositions, loadOrganizationEmployees } from '../../helpers/apis/index'
import { Row, Col, Card, CardBody, CardHeader, Button } from 'reactstrap';

const emptyOpening = {
    name: "",
    organization_id: "",
    department_id: "",
    position_id: "",
    hiring_manager_id: "",
    employment: "",
    work_from: "",
    vacancies: 0,
    job_desc: ""
};

const App = () => {
    const { openingID } = useParams()
    const [loggedUser] = useAtom(loggedInUser)

    const [opening, setOpening] = useState(emptyOpening)
    const navigate = useNavigate()

    const [organizationOptions, setOrganizationOptions] = useState([])
    const [organization, setOrganization] = useState([])

    const [allDepartments, setAllDepartments] = useState()
    const [departmentOptions, setDepartmentOptions] = useState([])
    const [department, setDepartment] = useState([])

    const [allPositions, setAllPostions] = useState()
    const [positionOptions, setPositionOptions] = useState()
    const [position, setPosition] = useState()

    const [allManagers, setAllManagers] = useState()
    const [managerOptions, setManagerOptions] = useState()
    const [manager, setManager] = useState()

    const workOptions = [
        { value: 1, label: "Office", option: 'office' },
        { value: 2, label: "Home", option: 'home' },
        { value: 3, label: "Hybrid", option: 'hybrid' },
    ]
    const [work, setWork] = useState()

    const employmentOptions = [
        { value: 1, label: "Full Time", option: 'full_time' },
        { value: 2, label: "Half Time", option: 'half_time' },
        { value: 3, label: "Contract", option: 'contract' },
        { value: 4, label: "Temporary", option: 'temporary' }
    ]
    const [employment, setEmployment] = useState()

    async function loadOpening() {
        let { status, data } = await get(
            OPENING + "/id/" + openingID,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            const { name, organizationId, departmentId, positionId, hiringManagerId, employment, vacancies, workFrom, jobDesc } = data
            setOpening({
                name,
                organization_id: organizationId,
                department_id: departmentId,
                position_id: positionId,
                hiring_manager_id: hiringManagerId,
                employment,
                vacancies,
                work_from: workFrom,
                job_desc: jobDesc
            })
            setOrganization({
                value: data.Organization.id,
                label: data.Organization.name
            })
            setDepartment({
                value: data.Department.id,
                label: data.Department.name
            })
            setPosition({
                value: data.Position.id,
                label: data.Position.name
            })
            setEmployment(employmentOptions.find(items => items.option === employment))
            setWork(workOptions.find(items => items.option === workFrom))
        }
    }

    useEffect(() => {
        setManager(allManagers?.find(items => items.value === opening?.hiring_manager_id))
    }, [opening.hiring_manager_id, allManagers])

    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let Errors = ValidateSchema(opening, AddEditJobOpeningSchema)
        setErrors(Errors);
        if (Object.keys(Errors).length) return false;
        return true;
    };

    useEffect(() => {
        loadOrganization(loggedUser, setOrganizationOptions)
        loadDepartment(loggedUser, setAllDepartments)
        loadPositions(loggedUser, setAllPostions)
        loadOrganizationEmployees(loggedUser, setAllManagers)
    }, [loggedUser])

    useEffect(() => {
        if (loggedUser?.user?.userType !== "super_admin") {
            setDepartmentOptions(allDepartments)
            setPositionOptions(allPositions)
            setManagerOptions(allManagers)
        }
    }, [allDepartments, allPositions, allManagers])

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setOpening({ ...opening, [name]: value });
    };

    const handleOrganizationSelect = (e) => {
        setOrganization(e)
        setOpening({ ...opening, organization_id: e.value })
        setDepartment(null)
        setPosition(null)
        setManager(null)
    }

    useEffect(() => {
        setDepartmentOptions(allDepartments?.filter(dat => dat.organization_id === organization.value))
        setPositionOptions(allPositions?.filter(dat => dat.organization_id === organization.value))
        setManagerOptions(allManagers?.filter(dat => dat.organization_id === organization.value))
    }, [organization])

    const handleSubmit = async () => {
        if (!validateForm()) return;
        let { status, message } = await post(
            OPENING + "/addorUpdate" + (openingID ? `/${openingID}` : ""),
            opening,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status) {
            showToast(status, message)
            if (status === 200) {
                navigate('/openings')
            }
        }
    };

    useEffect(() => {
        if (openingID) loadOpening();
    }, [])

    return <>
        <Row>
            <Col sm="12">
                <Card>
                    <CardHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">{openingID ? "Update" : "Create New"} Job Opening</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><Link to="/openings">Job Opening</Link></li>
                                        <li className="breadcrumb-item active"> Job Opening Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </CardHeader>
                </Card>
            </Col>

            <Col sm="12">
                <Card>
                    <CardBody>
                        <div className={classes.OpeningView}>

                            <div  >
                                <label className="Starter">Job Opening Name</label>
                                <input
                                    value={opening.name}
                                    className={errors?.name ? "form-control textInput errorInput " : "form-control textInput"}
                                    onChange={handleChange}
                                    type="text"
                                    placeholder="Opening Name"
                                    name="name"
                                />
                                <p className="errorText">{errors?.name || ""}</p>
                            </div>

                            {loggedUser?.user?.userType === "super_admin" && <div  >
                                <label className="Starter">
                                    Organization
                                </label>
                                <Select
                                    options={organizationOptions}
                                    value={organization}
                                    onChange={handleOrganizationSelect}
                                    name="organization_id"
                                // className={errors?.organization_name ? "errorInput " : ""}
                                />
                                {/* <p className="errorText">{errors?.organization_id || ""}</p> */}
                            </div>}

                            <div  >
                                <label className="Starter">
                                    Department
                                </label>
                                <Select
                                    options={departmentOptions}
                                    value={department}
                                    onChange={(e) => {
                                        setDepartment(e)
                                        setOpening({ ...opening, department_id: e.value })
                                    }}
                                    name="department_id"
                                    placeholder="Select Department"
                                    classNames={errors?.department ? "errorInput" : ""}
                                />
                                <p className="errorText">{errors?.department || ""}</p>
                            </div>

                            <div>
                                <label className="Starter">
                                    Employment Type
                                </label>
                                <Select
                                    options={employmentOptions}
                                    value={employment}
                                    onChange={(e) => {
                                        setEmployment(e)
                                        setOpening({ ...opening, employment: e.option })
                                    }}
                                    name="employment"
                                    placeholder="Select Employement"
                                    classNames={errors?.employment ? "css-b62m3t-container errorInput" : "css-b62m3t-container"}
                                />
                                <p className="errorText">{errors?.employment || ""}</p>
                            </div>

                            <div  >
                                <label className="Starter">
                                    Position
                                </label>
                                <Select
                                    options={positionOptions}
                                    value={position}
                                    onChange={(e) => {
                                        setPosition(e)
                                        setOpening({ ...opening, position_id: e.value })
                                    }}
                                    name="position"
                                    placeholder="Select Position"
                                    classNames={errors?.position ? "css-b62m3t-container errorInput" : ""}
                                />
                                <p className="errorText">{errors?.position || ""}</p>
                            </div>

                            <div>
                                <label className="Starter">
                                    Hiring Manager
                                </label>
                                <Select
                                    options={managerOptions}
                                    value={manager}
                                    onChange={(e) => {
                                        setManager(e)
                                        setOpening({ ...opening, hiring_manager_id: e.value })
                                    }}
                                    name="hiring_manager"
                                    placeholder="Select Manager"
                                    classNames={errors?.hiring_manager ? " css-13cymwt-control errorInput" : "css-13cymwt-control"}
                                />
                                <p className="errorText">{errors?.hiring_manager || ""}</p>
                            </div>

                            <div>
                                <label className="Starter">
                                    Work From
                                </label>
                                <Select
                                    options={workOptions}
                                    value={work}
                                    onChange={(e) => {
                                        setWork(e)
                                        setOpening({ ...opening, work_from: e.option })
                                    }}
                                    name="work_from"
                                    placeholder="Select Work Type"
                                    classNames={errors?.work_from ? "select errorInput" : "select"}
                                />
                                <p className="errorText">{errors?.work_from || ""}</p>
                            </div>



                            <div>
                                <label className="Starter">Total Vacancies</label>
                                <input
                                    value={opening.vacancies}
                                    className={errors?.vacancies ? "form-control textInput errorInput " : "form-control textInput"}
                                    onChange={handleChange}
                                    type="number"
                                    placeholder="Total Vacancies"
                                    name="vacancies"
                                />
                                <p className="errorText">{errors?.vacancies || ""}</p>
                            </div>
                        </div>
                        <div>
                            <label className="Starter">Job Description</label>
                            <textarea
                                value={opening.job_desc}
                                className={errors?.job_desc ? "form-control textInput errorInput " : "form-control textareaH "}
                                onChange={handleChange}
                                type="text"
                                placeholder="Job Description"
                                name="job_desc"
                            />
                            <p className="errorText">{errors?.job_desc || ""}</p>
                        </div>
                    </CardBody>
                </Card>
            </Col>

            <Col sm="12">
                <Card>
                    <CardBody>
                        <div className="rightItem">
                            <Button
                                className="Createbutton"
                                color='info'
                                onClick={handleSubmit}
                            >
                                {openingID ? "Update " : "Create "}
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
}

export default App;