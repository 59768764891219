import { Button } from "reactstrap"
import { useAtom } from 'jotai';
import { loggedInUser } from '../../utils/store';
import { useEffect, useState } from 'react';
import Permission from '../../utils/functions/Permission'

const ActiveButtons = (props) => {
    const [loggedUser] = useAtom(loggedInUser)
    const [permission, setPermission] = useState()
    useEffect(() => {
        if (loggedUser?.user?.userType === "employee")
            Permission(loggedUser)
                .then((result) => {
                    setPermission(result.Permissions.find(data => data.name === (props.module?.toLowerCase() + "-edit")))
                })
    }, [loggedUser])

    return (
        (loggedUser?.user?.userType !== "employee" || permission) &&
        <div>
            <Button
                color="danger"
                className="deactivate"
                onClick={props.Deactivate}
            >
                Deactivate
            </Button>
            <Button
                color="info"
                className="activate"
                onClick={props.activate}
            >
                Activate
            </Button>
        </div>
    )
}
export default ActiveButtons