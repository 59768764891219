import React from "react";
import SideNav from "../components/SideNav";
import Navbar from "../components/Navbar";
import { Outlet } from "react-router-dom";
import { Route, useLocation } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import { HiListBullet } from 'react-icons/hi2'
import { AiOutlineAlignCenter } from 'react-icons/ai'
import "../assets/css/nucleo-icons.css"
import "../assets/demo/demo.css";


var ps;

const DashboardLayout = ({ name, role, permissions }, props) => {
  const [activeColor, setActiveColor] = React.useState("blue");
  const [sidebarMini, setSidebarMini] = React.useState(true);
  const [opacity, setOpacity] = React.useState(0);
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const mainPanelRef = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);

  React.useEffect(() => {
    let innerMainPanelRef = mainPanelRef;
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.classList.add("perfect-scrollbar-on");
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current);
      mainPanelRef.current &&
        mainPanelRef.current.addEventListener("ps-scroll-y", showNavbarButton);
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    window.addEventListener("scroll", showNavbarButton);
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
        innerMainPanelRef.current &&
          innerMainPanelRef.current.removeEventListener(
            "ps-scroll-y",
            showNavbarButton
          );
      }
      window.removeEventListener("scroll", showNavbarButton);
    };
  }, []);

  const showNavbarButton = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement.scrollTop > 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop > 50)
    ) {
      setOpacity(1);
    } else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement.scrollTop <= 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop <= 50)
    ) {
      setOpacity(0);
    }
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const handleActiveClick = (color) => {
    setActiveColor(color);
  };

  const handleMiniClick = () => {
    let notifyMessage = "Sidebar mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      setSidebarMini(false);
      notifyMessage += "deactivated...";
    } else {
      setSidebarMini(true);
      notifyMessage += "activated...";
    }
    let options = {};
    options = {
      place: "tr",
      message: notifyMessage,
      type: "primary",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    // notificationAlertRef.current.notificationAlert(options);
    document.body.classList.toggle("sidebar-mini");
  };

  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
    document.documentElement.classList.toggle("nav-open");
  };

  const closeSidebar = () => {
    setSidebarOpened(false);
    document.documentElement.classList.remove("nav-open");
  };

  return (
    <>
      <div className="wrapper">
        <div className="navbar-minimize-fixed" style={{ opacity: opacity }}>
          <button
            className="minimize-sidebar btn btn-link btn-just-icon"
            onClick={handleMiniClick}
          >
            <AiOutlineAlignCenter className="visible-on-sidebar-regular navbarIcons" />
            <HiListBullet className="visible-on-sidebar-mini navbarIcons" />
          </button>
        </div>
        <SideNav
          name={name}
          role={role}
          permissions={permissions}
          closeSidebar={closeSidebar}
          activeColor={activeColor}
        />
        <div className="main-panel" ref={mainPanelRef} data={activeColor}>
          <Navbar
            {...props}
            handleMiniClick={handleMiniClick}
            brandText={"HRMS"}
            sidebarOpened={sidebarOpened}
            toggleSidebar={toggleSidebar}
          />

          <div className="content">
            <Outlet />
          </div>

        </div>
        {/* <FixedPlugin
          activeColor={activeColor}
          sidebarMini={sidebarMini}
          handleActiveClick={handleActiveClick}
          handleMiniClick={handleMiniClick}
        /> */}
      </div>
    </>
  );
};

export default DashboardLayout;
