import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import classes from '../../assets/styles/department.module.css'
import { useParams, useNavigate, Link } from "react-router-dom";
import { get, post } from "../../server";
import ValidateSchema from "../../helpers/ValidateSchema";
import AddEditDepartmentSchema from '../../utils/SchemasValidation/AddEditDepartmentSchema'
import { DEPARTMENT, EMPLOYEE } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";
import { Row, Col, Card, CardBody, CardHeader, Button } from 'reactstrap';
import { loadOrganization } from '../../helpers/apis'

const emptyDepartment = {
    name: "",
};

const App = () => {
    const { departmentID } = useParams()
    const [loggedUser] = useAtom(loggedInUser)

    const [department, setDepartment] = useState(emptyDepartment)
    const navigate = useNavigate()
    const [position, setPosition] = useState()
    const [role, setRole] = useState()
    const [organizationOptions, setOrganizationOptions] = useState([])
    const [organization, setOrganization] = useState([])
    const [leadOptions, setLeadOptions] = useState()
    const [lead, setLead] = useState()

    useEffect(() => {
        setRole(loggedUser?.user?.userType)
        if (loggedUser?.position) setPosition(loggedUser?.position)
    }, [loggedUser])

    async function loadDepartment() {
        let { status, data } = await get(
            DEPARTMENT + `/id/${departmentID}`,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            const { name, organizationId } = data
            setOrganization({ value: data.Organization.id, label: data.Organization.name })
            setDepartment({
                name,
                organization_id: organizationId
            })
        }
    }

    async function loadLeads() {
        let { status, data } = await get(
            EMPLOYEE + `/leads/get`,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === "ok") {
            setLeadOptions(data?.map(({ id, first_name, last_name }) => ({
                value: id,
                label: first_name + " " + (last_name === null ? "" : last_name)
            })))
        }
    }

    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let Errors = ValidateSchema(department, AddEditDepartmentSchema)
        setErrors(Errors);
        if (Object.keys(Errors).length) return false;
        return true;
    };

    useEffect(() => {
        loadLeads()
        if (loggedUser?.user.userType === "super_admin")
            loadOrganization(loggedUser, setOrganizationOptions)
    }, [])

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDepartment({ ...department, [name]: value });
    };

    const handleSelectChange = (selectedOptions) => {
        const selectedLeads = selectedOptions.map((option) => ({
            lead_id: option.value,
            lead_name: option.label,
        }));

        setLead(selectedOptions)
        setDepartment({
            ...department, leads: selectedLeads
        })
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        let { status, message } = await post(
            DEPARTMENT + "/addOrUpdate/" + (departmentID ? departmentID : ""),
            department,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            navigate("/department");
        }
        showToast(status, message);
    };

    useEffect(() => {
        if (departmentID) loadDepartment();
    }, [])

    const handleOrgSelect = (e) => {
        setOrganization(e)
        setDepartment({ ...department, organization_id: e.value })
    }

    return <>
        <Row>
            <Col sm="12">
                <Card>
                    <CardHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">{departmentID ? "Update " : "Create new "}Department</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" ><Link to="/department">Department</Link></li>
                                        <li className="breadcrumb-item active">Dashboard Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </CardHeader>
                </Card>
            </Col>

            <Col sm="12">
                <Card>
                    <CardHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <h2 className="card-title">Department Data</h2>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <div className={classes.departmentView}>
                            {role === "super_admin" && <div>
                                <label className='Starter'>
                                    Organization*
                                </label>
                                <Select
                                    options={organizationOptions}
                                    value={organization}
                                    onChange={handleOrgSelect}
                                    name="organization_id"
                                    className={errors?.organization_id ? "errorInput " : ""}
                                />
                                <p className="errorText">{errors?.organization_id || ""}</p>
                            </div>}

                            <div>
                                <label className='Starter'>Department Name*</label>
                                <input
                                    value={department.name}
                                    className={errors?.name ? "form-control textInput errorInput " : "form-control textInput"}
                                    onChange={handleChange}
                                    type="text"
                                    placeholder="Department Name"
                                    name="name"
                                />
                                <p className="errorText">{errors?.name || ""}</p>
                            </div>

                            {position !== "Lead" && <div>
                                <label className='Starter'>
                                    Department Lead
                                </label>
                                <Select
                                    options={leadOptions}
                                    value={lead}
                                    isMulti={true}
                                    onChange={handleSelectChange}
                                    name="lead"
                                    className={errors?.leads ? "errorInput " : ""}
                                />
                                <p className="errorText">{errors?.leads || ""}</p>
                            </div>}
                        </div>
                    </CardBody>
                </Card>
            </Col>

            <Col sm="12">
                <Card>
                    <CardBody>
                        <div className="rightItem">
                            <Button
                                color="info "
                                className="Createbutton"
                                onClick={handleSubmit} >
                                {departmentID ? "Update " : "Create "}
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
}

export default App;