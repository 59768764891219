import React, { useEffect, useState, useRef } from 'react'
import classes from '../../assets/styles/clockIn.module.css'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";
import TableComponent from '../../components/Global Components/DataTable'
import TableFilter from '../../components/Global Components/TableFilter';
import Select from 'react-select'
import { loadOrganization } from '../../helpers/apis'
import { DisputeViewer } from '../../components/Modal/disputeViewer'
import { Button, Col, Row, Card, CardBody, CardHeader } from 'reactstrap'
import { Link } from 'react-router-dom'
import { get } from '../../server';
import { ATTENDANCE } from '../../utils/constants/apiUrl';
import DatePicker from "react-multi-date-picker";
import { BiRefresh } from 'react-icons/bi';

const App = () => {
    const [loggedUser] = useAtom(loggedInUser);
    const [date, setDate] = useState(new Date().toISOString())
    const [data, setData] = useState()
    const [Data, setDATA] = useState()
    const [query, setQuery] = useState("")
    const [filteredData, setFilteredData] = useState()

    const [organization, setOrganization] = useState()
    const [organizationOptions, setOrganizationOptions] = useState([])
    const [modal, setModal] = React.useState(false);
    const employeeData = useRef()
    const option = [{ value: 1, label: "Dispute" }]

    async function loadClocks() {
        let { status, data } = await get(
            ATTENDANCE + "/clockInOut/listing",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status === 200) {
            setData(data)
            setDATA(data.filter(data => data.date.includes(date?.slice(0, 10))))
        }
    }

    useEffect(() => {
        loadOrganization(loggedUser, setOrganizationOptions)
        loadClocks()
    }, [])

    useEffect(() => {
        loadClocks()
    }, [modal])

    const handleAction = (row) => {
        employeeData.current = row
        toggleModal()
    }

    const toggleModal = () => {
        setModal(!modal);
    };
    const columns = [
        {
            name: 'Name',
            selector: row => row?.Employee?.User?.firstName,
            sortable: true,
            cell: (row) => <div
            >
                {row?.Employee?.User?.firstName} {row?.Employee?.User?.lastName}
            </div >
        },
        {
            name: "Organization",
            selector: row => row?.Organization?.name,
            sortable: true,
            omit: loggedUser?.user?.userType === "super_admin" ? false : true
        },
        {
            name: 'Date',
            selector: row => row.date.slice(0, 10),
            sortable: true,
            center: true,
        },
        {
            name: 'Clock In',
            selector: row => row.clockIn,
            sortable: true,
            center: true,
        },
        {
            name: 'Clock Out',
            selector: row => row.clockOut,
            sortable: true,
            center: true,
        },
        {
            name: 'Hours',
            selector: row => row.hours,
            sortable: true,
            center: true,
        },
        {
            name: "Action",
            selector: row => row.dispute,
            center: true,
            cell: row => <div>
                {row?.ClockInOut_Disputes.find(data => data.status !== "approved") ? <Button
                    className={`btn-simple ${classes.viewDispute}`}
                    color="info"
                    onClick={() => handleAction(row)}
                >View Disputes</Button> : "NaN"}
            </div>
        },
    ]

    useEffect(() => {
        setFilteredData(Data?.filter(dat => dat.Employee?.User?.firstName.toLowerCase().includes(query) || dat.Employee?.User?.lastName.toLowerCase().includes(query)))
    }, [query])

    const handleSelectChange = (selectedOptions) => {
        setOrganization(selectedOptions)
        if (selectedOptions === null) {
            if (query)
                setFilteredData(Data?.filter(dat => dat.Employee?.User?.firstName.toLowerCase().includes(query) || dat.Employee?.User?.lastName.toLowerCase().includes(query)))
            if (typeof date === "object")
                setDATA(data?.filter(data => data.date.includes(date?.format("YYYY-MM-DD"))))
            else
                setDATA(data?.filter(data => data.date.includes(date?.slice(0, 10))))
        }
        else {
            let filtered
            if (typeof date === "object")
                filtered = data?.filter(data => data.date.includes(date?.format("YYYY-MM-DD"))).filter(data => data.organizationId === selectedOptions?.value)
            else
                filtered = data?.filter(data => data.date.includes(date?.slice(0, 10))).filter(data => data.organizationId === selectedOptions?.value)
            if (query)
                setFilteredData(Data?.filter(dat => dat.Employee?.User?.firstName.toLowerCase().includes(query) || dat.Employee?.User?.lastName.toLowerCase().includes(query)))
            setDATA(filtered)
        }
    };

    return <>
        <Row>
            <Col sm="12">
                <Card>
                    <CardHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">Clock In & Out Panel</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" ><Link to="/home">Dashboard</Link></li>
                                        <li className="breadcrumb-item active">Clock In & Out Panel</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </CardHeader>

                    <CardBody>

                        <Button color="success" className='create' onClick={() => loadClocks()}>
                            <BiRefresh className='buttonIcons' />
                            Refresh
                        </Button>
                        <TableFilter
                            query={query}
                            setQuery={setQuery}
                        />
                        {loggedUser?.user?.userType === "super_admin" && <Select
                            options={organizationOptions}
                            value={organization}
                            isClearable={true}
                            onChange={handleSelectChange}
                            placeholder="Select Organization"
                            className="inlineDropdown"
                        />}
                        <DatePicker
                            value={date}
                            placeholder="Select Date"
                            inputClass={`form-control ${classes.filter}`}
                            onChange={(e) => {
                                setDate(e)
                                setDATA(data?.filter(data => data.date.includes(e?.format("YYYY-MM-DD"))))
                            }}
                            calendarPosition="bottom-right"
                            format="DD MMMM YYYY"
                        />
                        <TableComponent
                            columns={columns}
                            Data={Data}
                            filteredData={filteredData}
                            query={query}
                            allCheck={false}
                        />

                        <DisputeViewer
                            modal={modal}
                            data={employeeData.current}
                            toggleModal={toggleModal}
                            role={"admin"}
                            loggedUser={loggedUser}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
}

export default App