import React, { useEffect, useState, useRef } from 'react'
import { Delete } from '../../components/Modal/Delete'
import { BulkDelete } from '../../components/Modal/BulkDelete'
import classes from '../../assets/styles/position.module.css'
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";

import { POSITION } from '../../utils/constants/apiUrl'
import { get } from "../../server";
import Select from 'react-select'
import { loadOrganization } from '../../helpers/apis'
import CreationButton from '../../components/Global Components/CreationButton';
import TableFilter from '../../components/Global Components/TableFilter';
import TableComponent from '../../components/Global Components/DataTable';
import BulkDeleteButton from '../../components/Global Components/BulkDelete';
import DeleteAction from '../../components/Global Components/DeleteAction';
import EditAction from '../../components/Global Components/EditAction';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { BiRefresh } from 'react-icons/bi';

const App = () => {
    const [loggedUser] = useAtom(loggedInUser);
    const navigate = useNavigate()

    const [data, setData] = useState()
    const [Data, setDATA] = useState()
    const [query, setQuery] = useState("")
    const [showModal, setShowModal] = useState("close")
    const [select, setSelect] = useState(false)
    const positionData = useRef()

    const [organization, setOrganization] = useState()
    const [organizationOptions, setOrganizationOptions] = useState([])

    const [filteredData, setFilteredData] = useState()
    const [id, setID] = useState()
    const [role, setRole] = useState()

    useEffect(() => {
        setRole(loggedUser?.user?.userType)
        setID(loggedUser?.id)
    }, [loggedUser])

    async function loadPositions() {
        let { status, data } = await get(
            POSITION + "/listing",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        );
        if (status === 200) {
            setData(data)
            setDATA(data)
        }
    }

    useEffect(() => {
        loadOrganization(loggedUser, setOrganizationOptions)
        loadPositions()
    }, [id])

    const handleCheck = ({ selectedRows }) => {
        if (selectedRows.length > 0)
            setSelect(true)
        else if (selectedRows.length === 0)
            setSelect(false)
        positionData.current = selectedRows
    }

    const columns = [
        {
            name: "Organization",
            selector: row => row.Organization?.name,
            sortable: true,
            omit: role === "super_admin" ? false : true
        },
        {
            name: 'Position',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Parent',
            selector: row => row.parent?.name,
            sortable: true
        },
        {
            name: 'Hierarchy Level',
            selector: row => row.hierarchyLevel,
            sortable: true
        },
        {
            name: 'Head Count',
            cell: row => <div
                className='redirect'
                onClick={() => navigate('/employee?position=' + row.name + "&org=" + row.organizationId)}
            >
                {row?.Employees?.length}
            </div>
        },
        {
            name: "Action",
            selector: row => row.action,
            // button: true,
            cell: (row) => <div className={classes.actionIcons}>
                <DeleteAction
                    Data={positionData}
                    row={row}
                    setShowModal={setShowModal}
                />
                <EditAction
                    link={"/positions/AddEditPositions"}
                    id={row.id}
                />

            </div>
        },
    ]

    useEffect(() => {
        setFilteredData(data?.filter(dat => dat.name.toLowerCase().includes(query)))
    }, [query])

    const handleSelectChange = (selectedOptions) => {
        setOrganization(selectedOptions)
        if (selectedOptions === null) {
            setDATA(data)
            if (query)
                setFilteredData(data.filter(dat => dat.name.toLowerCase().includes(query)))
        }
        else {
            const filtered = data.filter(data => data.organizationId === selectedOptions?.value)
            if (query)
                setFilteredData(filtered.filter(dat => dat.name.toLowerCase().includes(query)))
            setDATA(filtered)
        }
    };

    useEffect(() => { loadPositions() }, [showModal])

    return <>
        <Card>
            <CardHeader>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <h2 className="card-title">Positions View</h2>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/home">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Positions</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </CardHeader>

            <CardBody>
                <CreationButton link={"/positions/AddEditPositions"} module={"Positions"} />
                <Button color="success" className='create' onClick={() => loadPositions()}>
                    <BiRefresh className='buttonIcons' />
                    Refresh
                </Button>
                {select && <BulkDeleteButton
                    setShowModal={setShowModal}
                    module={"Positions"}
                />}
                <TableFilter
                    query={query}
                    setQuery={setQuery}
                />

                {role === "super_admin" && <Select
                    options={organizationOptions}
                    value={organization}
                    isClearable={true}
                    onChange={handleSelectChange}
                    placeholder="Select Organization"
                    className="inlineDropdown"
                />}


                <TableComponent
                    columns={columns}
                    Data={Data}
                    filteredData={filteredData}
                    handleCheck={handleCheck}
                    query={query}
                />

                <Delete showModal={showModal === "delete"}
                    setShowModal={setShowModal}
                    DATA={positionData.current}
                    type={"Position"}
                    module={POSITION}
                />
                <BulkDelete showModal={showModal === "Bulk-delete"}
                    setShowModal={setShowModal}
                    DATA={positionData.current}
                    module={POSITION}
                />
            </CardBody>
        </Card>
    </>
}

export default App