import React, { useState, useEffect, useRef } from 'react'
import classes from '../../assets/styles/permissions.module.css'
import Select from 'react-select'
import { BiArrowBack } from 'react-icons/bi'
import { AiOutlinePlus } from 'react-icons/ai'
import { useParams, useNavigate, Link } from "react-router-dom";
import { get, post, put, deleteRecord } from "../../server";
import ValidateSchema from "../../helpers/ValidateSchema";
import PermissionsSchema from '../../utils/SchemasValidation/Roles&PermissionsSchema'
import { ROLE } from '../../utils/constants/apiUrl'
import showToast from '../../helpers/showToast'
import TableComponent from '../../components/Global Components/DataTable';
import { loadOrganization } from '../../helpers/apis'

import { useAtom } from 'jotai';
import { loggedInUser } from '../../utils/store';
import { Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';

const emptyPermission = {
    name: "",
    organization_id: ""
};

const newPerm = [
    {
        module_names: "Departments",
        name: 'departments-',
        index: false,
        create: false,
        edit: false,
        delete: false,
    },
    {
        module_names: "Projects",
        name: 'projects-',
        index: false,
        create: false,
        edit: false,
        delete: false,
    },
    {
        module_names: "Employess",
        name: 'employees-',
        index: false,
        create: false,
        edit: false,
        delete: false,
    },
    {
        module_names: "Attendance",
        name: 'attendance-',
        index: false,
        create: false,
        edit: false,
    },
    {
        module_names: "Leaves",
        name: 'leaves-',
        index: false,
        create: false,
    },
    {
        module_names: "Late Criterias",
        name: 'lateCriterias-',
        index: false,
        edit: false,
        delete: false,
    },
    {
        module_names: "Job Openings",
        name: 'openings-',
        index: false,
        create: false,
        edit: false,
        delete: false,
    },
    {
        module_names: "Candidates",
        name: 'candidates-',
        index: false,
        create: false,
        edit: false,
    },
]

const App = () => {
    const [loggedUser, setLoggedUser] = useAtom(loggedInUser)
    const { permissionID } = useParams()
    const navigate = useNavigate()
    const [organization, setOrganization] = useState()
    const [organizationOptions, setOrganizationOptions] = useState([])

    const [Permissions, setPermissions] = useState()
    //     [
    //     { id: "1", permission_name: "Manager", permissions: Manager, organization_id: 1 },
    //     { id: "2", permission_name: "Lead 1", permissions: Lead, organization_id: 1 },
    //     { id: "3", permission_name: "Senior", permissions: Senior, organization_id: 1 },
    //     { id: "4", permission_name: "Junior", permissions: Junior, organization_id: 1 },
    //     { id: "5", permission_name: "Intern", permissions: Intern, organization_id: 1 },
    //     { id: "6", permission_name: "Manager", permissions: Manager, organization_id: 2 },
    //     { id: "7", permission_name: "Lead 2", permissions: Lead, organization_id: 2 },
    //     { id: "8", permission_name: "Manager", permissions: Manager, organization_id: 5 },
    //     { id: "9", permission_name: "Senior", permissions: Senior, organization_id: 5 },
    //     { id: "10", permission_name: "Junior", permissions: Junior, organization_id: 5 },
    // ]


    const [Permission, setPermission] = useState(emptyPermission)
    const [data, setData] = useState()
    const [newPermission, setNewPermission] = useState(false)
    const [permissionName, setPermissionName] = useState()
    const PermissionData = useRef()

    async function loadPermissions() {
        let { status, data } = await get(
            ROLE + "/permissionsListing",
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status === 200) {
            setPermissions(data)
        }
    }

    async function loadPermission() {
        let { status, data } = await get(
            ROLE + "/id/" + permissionID,
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status === 200) {
            setPermission({
                name: data.name,
                organization_id: data.organizationId
            })
            const temp = newPerm
            for (let i = 0; i < data?.Permissions?.length; i++) {
                const { name } = data?.Permissions[i];
                const module = name.split("-")[0];
                const permission = name.split("-")[1];

                const index = temp.findIndex(item => item.name === `${module}-`);

                if (permission === "index") {
                    temp[index].index = true;
                } else if (permission === "create") {
                    temp[index].create = true;
                } else if (permission === "edit") {
                    temp[index].edit = true;
                } else if (permission === "delete") {
                    temp[index].delete = true;
                }
            }
            setData(temp)
        }
    }

    useEffect(() => {
        loadPermissions()
        loadOrganization(loggedUser, setOrganizationOptions)
    }, [])

    useEffect(() => {
        setOrganization(organizationOptions?.find(item => item.value === Permission.organization_id))
    }, [Permission.organization_id, organizationOptions])

    const handleChange = (e) => {
        setPermission({ ...Permission, name: e.target.value })
    };

    const handleAllCheck = ({ selectedRows }) => {
        const updatedPermissions = data.map(permission => {
            if (permission?.module_names === selectedRows[0]?.module_names) {
                if (permission.hasOwnProperty('index')) {
                    permission.index = true;
                } else {
                    delete permission.index;
                }
                if (permission.hasOwnProperty('create')) {
                    permission.create = true;
                } else {
                    delete permission.create;
                }
                if (permission.hasOwnProperty('edit')) {
                    permission.edit = true;
                } else {
                    delete permission.edit;
                }
                if (permission.hasOwnProperty('delete')) {
                    permission.delete = true;
                } else {
                    delete permission.delete;
                }
            }
            return permission;
        });

        setData(updatedPermissions)
    }

    const handleCheck = (e, row) => {
        const { name, checked } = e.target;
        const updatedPermissions = data.map(permission => {
            if (permission.module_names === row.module_names) {
                return {
                    ...permission,
                    [name]: checked,
                };
            }
            return permission;
        });
        setData(updatedPermissions)
    };

    const [errors, setErrors] = useState({});

    const validate = () => {
        let Error = ValidateSchema(Permission, PermissionsSchema)
        setErrors(Error);
        if (Object.keys(Error).length) return false;

        return true;
    };

    const handleSubmit = async (e) => {
        const ids = []
        const filteredArray = data.filter((obj) => obj.index || obj.create || obj.edit || obj.delete);

        const resultArray = filteredArray.map((obj) => {
            const result = [];
            if (obj.index) {
                result.push(`${obj.name}index`);
            }
            if (obj.create) {
                result.push(`${obj.name}create`);
            }
            if (obj.edit) {
                result.push(`${obj.name}edit`);
            }
            if (obj.delete) {
                result.push(`${obj.name}delete`);
            }
            return result;
        });
        const flattenedResultArray = resultArray.flat();

        for (let i = 0; i < flattenedResultArray.length; i++) {
            const entry = flattenedResultArray[i];
            const permission = Permissions.find(p => p.name === entry);
            const id = permission ? permission.id : null;
            ids.push(id)
        }
        let { status, message } = await post(
            ROLE + "/addorUpdate" + (permissionID ? `/${permissionID}` : ""),
            { name: Permission.name, organization_id: Permission.organization_id, permission_ids: ids },
            Object.keys(loggedUser).length !== 0 ? loggedUser?.token : null
        )
        if (status) {
            showToast(status, message)
            if (status === 200)
                navigate('/roles-permissions')
        }
    };

    const columns = [
        {
            name: "Module",
            selector: "module_names",
        },
        {
            name: "Listing",
            center: true,
            cell: (row) =>
                row.index !== undefined ? (
                    <input
                        type="checkbox"
                        checked={row.index}
                        name="index"
                        onChange={(e) => handleCheck(e, row)}
                    />
                ) : null,
        },
        {
            name: "Create",
            center: true,
            cell: (row) =>
                row.create !== undefined ? (
                    <input
                        type="checkbox"
                        checked={row.create}
                        name="create"
                        onChange={(e) => handleCheck(e, row)}
                    />
                ) : null,
        },
        {
            name: "Edit",
            center: true,
            cell: (row) =>
                row.edit !== undefined ? (
                    <input
                        type="checkbox"
                        checked={row.edit}
                        name="edit"
                        onChange={(e) => handleCheck(e, row)}
                    />
                ) : null,
        },
        {
            name: "Delete",
            center: true,
            cell: (row) =>
                row.delete !== undefined ? (
                    <input
                        type="checkbox"
                        checked={row.delete}
                        name="delete"
                        onChange={(e) => handleCheck(e, row)}
                    />
                ) : null,
        },
    ];

    useEffect(() => {
        if (permissionID) loadPermission();
    }, [])

    const handleSelectChange = (selectedOption) => {
        setOrganization(selectedOption)
        setPermission({ ...Permission, organization_id: selectedOption.value })
    };

    return <>
        <Row>
            <Col sm="12">
                <Card>
                    <CardHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="card-title">{permissionID ? "Update" : "Create New"} Role & Permission</h2>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><Link to="/roles-permissions">Roles & Permissions</Link></li>
                                        <li className="breadcrumb-item active">Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </CardHeader>
                </Card>
            </Col>

            <Col sm="12">
                <Card>
                    <CardBody>
                        <div className={classes.PermissionView}>

                            {loggedUser?.user?.userType === "super_admin" && <div>
                                <label className='Starter'>Organization</label>
                                <Select
                                    options={organizationOptions}
                                    value={organization}
                                    onChange={handleSelectChange}
                                    placeholder="Select Organization"
                                    isDisabled={permissionID ? true : false}
                                />
                            </div>}


                            <div>
                                <label className='Starter'>Permission Name</label>
                                <input
                                    value={Permission.name}
                                    className={errors?.name ? "form-control textInput errorInput " : "form-control textInput"}
                                    onChange={handleChange}
                                    type="text"
                                    placeholder="Permission Name"
                                    name="name"
                                />
                                <p className="errorText">{errors?.name || ""}</p>
                            </div>

                        </div>

                        {(!newPermission && !data) && <div className={classes.Relative}>
                            <Button
                                onClick={() => {
                                    if (!validate()) return
                                    if (organization) {
                                        setPermissionName(Permission.name)
                                        // setPermissions({ [Permission.name]: newPerm })
                                        setData(newPerm)
                                        setNewPermission(true)
                                    }
                                    else
                                        showToast("not ok", "Please select Organization")
                                }}
                                color="info"
                                className={classes.create}>
                                Create <AiOutlinePlus />
                            </Button>
                        </div>}

                        {data && <div className={classes.Relative}>
                            <Button
                                onClick={() => {
                                    if (!validate()) return
                                    handleSubmit()
                                }}
                                color="info"
                                className={classes.create}>
                                Save
                            </Button>
                        </div>}
                    </CardBody>
                </Card>
            </Col>

            <Col sm="12">
                <Card>
                    <CardBody>
                        <TableComponent
                            columns={columns}
                            Data={data}
                            handleCheck={handleAllCheck}
                            pagination={false}
                            selectableRowsSingle={true}
                            message={<div style={{ padding: '24px' }}>Please enter Permission Name to create permissions</div>}
                        />
                    </CardBody>
                </Card>

            </Col>
        </Row>
    </ >
}

export default App;