import customStyles from '../../assets/styles/Table Styles/tableStyle'
import DataTable from 'react-data-table-component'
import { useEffect, useState } from 'react'
import { ThreeCircles } from 'react-loader-spinner'

const TableComponent = (props) => {
    const [pending, setPending] = useState(true);
    const [key, setKey] = useState(0);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setPending(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        setKey(key => key + 1)
    }, [props.Data])

    const handlePageChange = page => {
        props.setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage) => {
        props.setPerPage(newPerPage);
        props.setCurrentPage(1)
    };

    return (
        <div className={!pending && 'animation'}>
            <DataTable
                key={key}
                pagination={props.pagination === false ? false : true}
                columns={props.columns}
                data={props.query ? props.filteredData : props.Data}
                selectableRows={props.allcheck === false ? false : true}
                selectableRowsSingle={props.selectableRowsSingle === true ? true : false}
                selectableRowsVisibleOnly
                onSelectedRowsChange={props.handleCheck}
                highlightOnHover
                responsive
                striped
                customStyles={customStyles}
                noDataComponent={props.message}
                progressPending={pending}
                pending
                progressComponent={<ThreeCircles color="#1d8cf8" />}
                paginationServer={props.paginationServer}
                paginationDefaultPage={props.paginationDefaultPage}
                paginationTotalRows={props.totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                paginationPerPage={props.perPage}
                defaultSortAsc={false}
            />
        </div>
    )
}

export default TableComponent