const Joi = require("joi");
const AddEmployeeLeaveSchema = Joi.object({
    id: Joi.allow(null),

    reason: Joi.string().required().messages({
        "string.empty": `Please enter Reason Type`,
    }),

    detail: Joi.string().min(1).max(25).required().messages({
        "string.empty": `Please enter Leave Reason`,
        "string.min": `username Last name must be minimum 5 characters!`,
        "string.max": `username must be maximum 25 characters!`,
    }),
});

export default AddEmployeeLeaveSchema;
