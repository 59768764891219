import { FaTrashAlt } from "react-icons/fa"
import { useAtom } from 'jotai';
import { loggedInUser } from '../../utils/store';
import React, { useEffect, useState } from 'react';
import Permission from '../../utils/functions/Permission'

const DeleteAction = (props) => {
    const [loggedUser] = useAtom(loggedInUser)
    const [permission, setPermission] = useState()
    useEffect(() => {
        if (loggedUser?.user?.userType === "employee")
            Permission(loggedUser)
                .then((result) => {
                    setPermission(result.Permissions.find(data => data.name === (props.module?.toLowerCase() + "-delete")))
                })
    }, [loggedUser])

    return (
        (loggedUser?.user?.userType !== "employee" || permission) &&
        <FaTrashAlt
            className="deleteIcon"
            onClick={() => {
                props.setShowModal("delete")
                props.Data.current = props.row
            }} />

    )
}

export default DeleteAction